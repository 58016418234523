import React, { FC } from 'react';
import styles from '../../JobPlacementInfo.module.scss';
import Button from 'components/shared/button/Button';

interface IJobPlacementInfoSectionTitle {
  title: string;
  editable?: boolean;
  buttonText?: string;
  onClickEdit?: () => void;
}

export const JobPlacementInfoSectionTitle: FC<IJobPlacementInfoSectionTitle> = ({
  title,
  editable = false,
  buttonText,
  onClickEdit,
}) => {
  return (
    <div className={styles.jobPlacementSectionTitleWrapper}>
      <span>{title}</span>
      {editable && <Button text={buttonText || ''} onClick={onClickEdit} classList={['editBtn']} />}
    </div>
  );
};

export default JobPlacementInfoSectionTitle;
