import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { confirmValidate } from 'utils/confirm-validate.utils';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import Input from 'components/shared/form-controls/input/Input';
import Button from 'components/shared/button/Button';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { updateUserApi, loginApi } from 'api/user';
import AuthHelpers from '../../utils/AuthHelpers';
import styles from 'pages/user-profile/UserProfile.module.scss';

interface IForm {
  currentPassword: string;
  password: string;
  confirm: string;
}

export const ChangePasswordModal: FC = () => {
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    getValues,
    formState: { errors },
  } = useForm<IForm>();

  confirmValidate(getValues('confirm'), getValues('password'), setError, watch, clearErrors);
  const navigate = useNavigate();
  const { hideModal, showModal } = useGlobalModalContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangePassword: SubmitHandler<IForm> = async ({ currentPassword, password }) => {
    const currentUser = AuthHelpers.getUserInfo();
    setIsDisabled(true);
    try {
      const loginApiResponse = await loginApi({
        username: currentUser.email,
        password: currentPassword,
      });
      if (loginApiResponse?.access_token) {
        await updateUserApi({ email: currentUser.email, password: password });
        navigate('/');
        showModal(MODAL_TYPES.SIGN_IN_MODAL);
      }
    } catch (e) {
      setError('currentPassword', {
        type: 'incorrect-password',
        message: 'The current password is not correct',
      });
    }
  };
  const handleCancel = () => {
    hideModal();
  };
  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <h2 className={styles.title}>{'Change your password'}</h2>
      <form className={`form ${styles.form}`} onSubmit={handleSubmit(onChangePassword)}>
        <Input
          inputLabel="Enter current password"
          label="currentPassword"
          register={register}
          type="password"
          placeholder="Enter current password"
          errors={errors}
        />
        <Input
          inputLabel="Enter new password"
          label="password"
          register={register}
          type="password"
          placeholder="Enter new password"
          errors={errors}
        />
        <Input
          inputLabel="Confirm new password"
          label="confirm"
          register={register}
          type="password"
          placeholder="Confirm new password"
          errors={errors}
        />
        <div className={styles.actionButtons}>
          <Button text="Cancel" classList={['white']} onClick={handleCancel} />
          <Button text="Save" classList={['filledRed']} disabled={isDisabled} />
        </div>
      </form>
    </ModalWindow>
  );
};

export default ChangePasswordModal;
