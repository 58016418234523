import React, { ChangeEvent, FC } from 'react';
import styles from 'components/shared/cards/card/Card.module.scss';
import { Link, useLocation } from 'react-router-dom';
import Button from 'components/shared/button/Button';
import { ICard } from 'interfaces/card';
import RatingStars from 'components/shared/rating-stars/RatingStars';
import { ROUTE_USER_RATED_COMPANIES } from 'app-constants';
import ReviewLabel from 'components/shared/review-label/ReviewLabel';
import deleteIcon from 'assets/images/icons/delete.svg';

export const Card: FC<ICard> = ({
  type,
  description,
  imageUrl,
  link,
  rating,
  title,
  showLearnMoreBtn = true,
  reviewLabel,
  onView,
  onDelete,
}) => {
  const { pathname } = useLocation();

  return (
    <Link className={styles.card} to={pathname === `/${ROUTE_USER_RATED_COMPANIES}` ? '' : link}>
      {type === 'card' && <img className={styles.cardImage} src={imageUrl} alt={'card'} />}
      <div className={`${styles.cardWrapper} ${styles[`cardWrapper--${type}`]}`}>
        {type === 'company' && (
          <div className={styles.companyInfo}>
            <div className={styles.companyInfoImage}>
              {imageUrl && <img src={imageUrl} alt={'company logo'} />}
            </div>
            {/*TODO check logic and refactor*/}
            {pathname === `/${ROUTE_USER_RATED_COMPANIES}` && (
              <RatingStars rating={rating || 0} clickable={false} />
            )}
            {pathname !== `/${ROUTE_USER_RATED_COMPANIES}` && reviewLabel && (
              <ReviewLabel label={reviewLabel} />
            )}
          </div>
        )}
        {title && <span className={styles.title}>{title}</span>}
        <p className={styles.description}>{description}</p>
        {showLearnMoreBtn && <Button text={'Learn More'} classList={['link']} />}
        {pathname === `/${ROUTE_USER_RATED_COMPANIES}` && (
          <div className={styles.ratedButtons}>
            <Button
              text={'View'}
              classList={['borderedRed']}
              onClick={(e: ChangeEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                if (onView) {
                  onView();
                }
              }}
            />
            <Button
              text={''}
              type={'icon'}
              iconLink={deleteIcon}
              onClick={(e: ChangeEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                if (onDelete) {
                  onDelete();
                }
              }}
            />
          </div>
        )}
      </div>
    </Link>
  );
};

export default Card;
