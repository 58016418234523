import React, { FC } from 'react';
import styles from './Button.module.scss';

const buttonType = 'button';
const iconType = 'icon';

type TButton = typeof buttonType | typeof iconType;

interface IButton {
  text: string;
  type?: TButton;
  iconLink?: string;
  onClick?: (e?: any) => void;
  classList?: string[];
  disabled?: boolean;
}

export const Button: FC<IButton> = ({
  text,
  type = 'button',
  iconLink,
  onClick,
  classList,
  disabled,
}) => {
  const getClassString = (): string => {
    if (disabled) {
      classList?.push('disabled');
    }
    return classList?.length ? classList?.map((classItem) => styles[classItem]).join(' ') : '';
  };

  return (
    <button
      className={`${styles.button} ${getClassString()} ${type === iconType && styles.icon}`}
      onClick={onClick}
      disabled={disabled}
    >
      {type === buttonType ? text : <img src={iconLink} />}
    </button>
  );
};

export default Button;
