import React, { FC, useEffect, useState } from 'react';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import PageTitle from 'components/common/page-title/PageTitle';
import Input from 'components/shared/form-controls/input/Input';
import Select, { ISelectOption } from 'components/shared/form-controls/select/Select';
import Button from 'components/shared/button/Button';
import { useForm } from 'react-hook-form';
import {
  createCompany,
  getCountries,
  getIndustries,
  ICreateCompanyData,
  uploadCompanyLogo,
  uploadCVFile,
} from 'api';
import { EMAIL_REGEX, LINK_REGEX, ONLY_NUMBERS_REGEX } from 'app-constants';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import Loader from 'components/common/loader/Loader';
import AuthHelpers from 'utils/AuthHelpers';

interface IAddCompany {
  name: string;
  country: string;
  city_or_zip_code: string;
  website: string;
  email: string;
  phone_number: string;
  logo: string;
  sector: string;
  employees_count: string;
  short_description: string;
}

// {
//   "logo": "fugiat in",
//   "rating": -66928114,
//   "user": [
//   {
//     "email": "aKg@OgpeNACQ.zfr",
//     "id": "laborum ad",
//     "is_active": true,
//     "is_superuser": false,
//     "is_verified": false,
//     "username": "",
//     "avatar_link": "http://VDBVxJWtPTyTUHbNAbGXLDlKmkBt.weckmTnVFP0WDhfMow6t2kWUZzAAUMWzxmxYrbyFbNSi3jV-kcGsMnR-NbXUREXWokJ",
//     "roles": [
//       "Blog Admin",
//       "Blog Admin"
//     ],
//     "want_newsletter": false
//   }
// ],
//   "status": "Approved",
//   "address": "aute ad",
//   "review_count": 0,
// }

export const AddCompany: FC = () => {
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<IAddCompany>({
    mode: 'onChange',
  });

  const [countries, setCountries] = useState<ISelectOption[]>([]);
  const [industries, setIndustries] = useState<ISelectOption[]>([]);
  const [logoUploading, setLogoUploading] = useState<boolean>(false);
  const { showModal } = useGlobalModalContext();

  useEffect(() => {
    getCountries().then((res) => {
      const data: ISelectOption[] = res.map((item) => {
        return { label: item, value: item };
      });

      setCountries(data);
    });

    getIndustries().then((res) => {
      const data: ISelectOption[] = res.map((item) => {
        return { label: item, value: item };
      });

      setIndustries(data);
    });
  }, []);

  const handleSubmitForm = (data) => {
    if (!AuthHelpers.getUserInfo()) {
      showModal(MODAL_TYPES.SIGN_IN_MODAL);
      return;
    }

    const postData: ICreateCompanyData = {
      ...data,
      user: [AuthHelpers.getUserInfo()],
    };

    createCompany(postData)
      .then((res) => {
        showModal(MODAL_TYPES.COMPANY_ON_REVIEW_MODAL);
      })
      .catch((err) => console.error(err));
  };

  const handleLogoUpload = (file) => {
    const formData = new FormData();
    formData.append('logo', file);
    setLogoUploading(true);
    uploadCompanyLogo(formData)
      .then((res) => {
        setValue('logo', res.direct_url);
        // setUploadedFile({ file: event.target.files[0], cv_link: res.direct_url });
        setLogoUploading(false);
      })
      .catch((e) => {
        console.error(e);
        setLogoUploading(false);
      });
  };

  return (
    <>
      <PageTitle title={'Add a Company'} />

      <div className="section__block no-top-padding">
        <PageInfoCard classList={['center', 'no-margin']}>
          <PageTitle type={'card'} title={'Company Information'} />

          <div className="info-card-content center">
            <form className="form vertical">
              <Input
                inputLabel="Company name:"
                label="name"
                register={register}
                required={true}
                errors={errors}
                type="text"
                placeholder="Enter a company name"
              />
              <Select
                options={countries}
                control={control}
                name={'country'}
                required={true}
                errors={errors}
                selectLabel={'Country:'}
                selectPlaceholder={'Choose a country company is located'}
              />
              <Input
                inputLabel={getValues('country') === 'United States' ? 'ZIP Code:' : 'City:'}
                label="city_or_zip_code"
                register={register}
                required={true}
                errors={errors}
                type="text"
                placeholder={
                  getValues('country') === 'United States'
                    ? 'Enter ZIP code'
                    : 'Enter a city company is located'
                }
              />
              <Input
                inputLabel="Website:"
                label="website"
                register={register}
                required={false}
                errors={errors}
                type="text"
                placeholder="Enter a link to website"
                pattern={{
                  value: LINK_REGEX,
                  message: `Please enter a valid link`,
                }}
              />
              <Input
                inputLabel="Contacts:"
                label="email"
                register={register}
                required={true}
                errors={errors}
                type="text"
                placeholder="Enter an e-mail address"
                pattern={{
                  value: EMAIL_REGEX,
                  message: `Please enter a valid email`,
                }}
              />
              <Input
                inputLabel="&#8205;"
                label="phone_number"
                register={register}
                required={true}
                errors={errors}
                type="text"
                labelClassList={['mobile-hidden']}
                placeholder="Enter a phone number"
              />
              <Input
                inputLabel="Company Logo:"
                label="logo"
                register={register}
                required={true}
                errors={errors}
                type="file"
                placeholder={'Upload Company Logo'}
                onFileAdded={handleLogoUpload}
                onFileUploading={logoUploading}
              />
              {getValues('logo') && !logoUploading && (
                <img
                  className={'uploaded-logo'}
                  src={getValues('logo')}
                  alt={'Uploaded Logo'}
                  height="55px"
                />
              )}
              <Select
                options={industries}
                control={control}
                name={'sector'}
                required={true}
                errors={errors}
                selectLabel={'Sector:'}
                selectPlaceholder={'Select industry sector of your company'}
              />
              <Input
                inputLabel="Number of employees:"
                label="employees_count"
                register={register}
                required={true}
                errors={errors}
                type="text"
                placeholder="Choose a number of employees"
                pattern={{
                  value: ONLY_NUMBERS_REGEX,
                  message: `Please enter a number`,
                }}
              />
              <div className="form-group">
                <label className="label align-center">Company description:</label>
                <textarea
                  className={`input input-wrapper textarea`}
                  {...register('short_description')}
                  placeholder="Enter a Company description"
                  rows={5}
                />
              </div>
            </form>
            <Button
              text={'Complete'}
              onClick={handleSubmit((data) => handleSubmitForm(data))}
              disabled={!isValid}
            />
          </div>
        </PageInfoCard>
      </div>
    </>
  );
};

export default AddCompany;
