import React, { FC, useEffect, useState } from 'react';
import { JobPlacementCard } from 'pages/user-added-job-placements/components/job-placement-card/JobPlacementCard';
import InfoBanner from 'components/shared/info-banner/InfoBanner';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_JOB_PLACEMENT } from 'app-constants';
import { getMyJobPlacements, IJobPlacementBody } from 'api';
import Loader from 'components/common/loader/Loader';

export const JobPlacementList: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [jobPlacements, setJobPlacements] = useState<IJobPlacementBody[]>([]);
  const navigate = useNavigate();

  const location = useLocation();

  const handleAddJobPlacementButtonClick = () => {
    navigate(`/${ROUTE_JOB_PLACEMENT}`);
  };

  useEffect(() => {
    setLoading(true);
    getMyJobPlacements()
      .then((res) => {
        setJobPlacements(res.items);
      })
      .finally(() => setLoading(false));
  }, [location]);

  return (
    <>
      {loading ? (
        <div className="flexWrapper flexWrapper--center">
          <Loader />
        </div>
      ) : (
        <>
          {jobPlacements.length > 0 && !loading ? (
            <>
              {jobPlacements.map((jobPlacement, i) => {
                return (
                  <JobPlacementCard
                    key={i}
                    id={jobPlacement.id!}
                    status={jobPlacement.status!}
                    current_job_title={jobPlacement.current_job_title!}
                    current_job_category={jobPlacement.current_job_category!}
                    created={jobPlacement.created!}
                  />
                );
              })}
            </>
          ) : (
            <InfoBanner
              title={`You didn’t add any Job Placements yet`}
              text={
                'Add your first Job Placement, so it will be visible on our website and other people can find your Job Placement'
              }
              classList={['yellow']}
              buttonText={'Add a new Job Placement'}
              buttonClassList={['withPlus']}
              onButtonClick={handleAddJobPlacementButtonClick}
            />
          )}
        </>
      )}
    </>
  );
};

export default JobPlacementList;
