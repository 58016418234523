import React, { FC, useEffect } from 'react';
import { getParams } from 'utils/http.utils';
import { getUserApi, ILoginRes, linkedinCallbackApi } from 'api';
import AuthHelpers, { getCookie, removeCookie } from 'utils/AuthHelpers';
import { useNavigate } from 'react-router-dom';
import Loader from 'components/common/loader/Loader';
import { SOCIAL_AUTH_SAVE_DATA } from 'app-constants';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';

export const LinkedInCallback: FC = () => {
  const navigation = useNavigate();
  const { setIsLogin } = useGlobalJobPlacementContext();

  useEffect(() => {
    const params = getParams();

    linkedinCallbackApi({ code: params.code, state: params.state }).then((response) => {
      storeUser(response).then(() => {
        const data = getCookie(SOCIAL_AUTH_SAVE_DATA);

        if (data) {
          navigation(data.redirectUrl);

          if (data.clearAfterLogin) {
            removeCookie(SOCIAL_AUTH_SAVE_DATA);
          }
        } else {
          navigation('/');
        }
      });
    });
  }, []);

  const storeUser = async (response: ILoginRes) => {
    AuthHelpers.storeAccessToken(response.access_token);
    const user = await getUserApi();
    AuthHelpers.storeUserInfo(user);
    setIsLogin(true);
  };

  return (
    <div className="flexWrapper flexWrapper--center">
      <Loader />
    </div>
  );
};

export default LinkedInCallback;
