import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardTitle from '../info-card-title/InfoCardTitle';
import InfoCardValue from '../info-card-value/InfoCardValue';

interface IProps {
  company: IBusinessItemInfo;
  isMyRated?: boolean;
}

const motherhoodSupport = [
  'Onsite child care',
  'Lactation rooms',
  'Accessible parking',
  'Discounted childcare',
];

const MotherhoodSupport: FC<IProps> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle text={'Motherhood Support'} />
      {company.motherhood_supports?.length && company.motherhood_supports[0] !== 'Not Offered' ? (
        motherhoodSupport.map((item, index) => {
          return (
            <InfoCardValue
              key={index}
              label={`${item}`}
              value={company?.motherhood_supports?.includes(item) ? 'Yes' : 'No'}
            />
          );
        })
      ) : company.motherhood_supports === null ? (
        '—\t'
      ) : (
        <InfoCardValue label={'Not Offered'} value={'Yes'} />
      )}
    </PageInfoCard>
  );
};

export default MotherhoodSupport;
