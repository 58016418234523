import React, { FC } from 'react';
import styles from './ReviewLabel.module.scss';

export type TReviewLabel = 'Approved' | 'Not Reviewed' | 'Rejected' | '';

interface IReviewLabel {
  label: TReviewLabel;
}

export const ReviewLabel: FC<IReviewLabel> = ({ label }) => {
  return (
    <div
      className={`${styles.reviewLabel} ${
        styles[`reviewLabel--${label.toLowerCase().replace(' ', '-')}`]
      }`}
    >
      {label}
    </div>
  );
};

export default ReviewLabel;
