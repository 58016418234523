import UserFlow from 'components/common/user-flow/UserFlow';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import React, { FC, useEffect, useState } from 'react';
import AuthHelper, { getCookie, removeCookie, setCookie } from '../../../utils/AuthHelpers';
import { ROUTE_JOB_PLACEMENT, SOCIAL_AUTH_SAVE_DATA } from 'app-constants';
import JPStepFour from 'pages/job-placement/sub-pages/StepFour';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import Button from 'components/shared/button/Button';
import { useNavigate } from 'react-router-dom';

export const JPStepThree: FC = () => {
  const {
    store: { steps, jobPlacementForm },
    handleClickStep,
  } = useGlobalJobPlacementContext();

  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();

  const accessToken = AuthHelper.getAccessToken();
  const [isLogin, setIsLogin] = useState(accessToken);

  const navigate = useNavigate();

  const handleOnSignInClick = () => {
    setIsLogin(true);
  };

  const handleSocialAuthClick = () => {
    setCookie(
      SOCIAL_AUTH_SAVE_DATA,
      JSON.stringify({ redirectUrl: `/${ROUTE_JOB_PLACEMENT}/step3`, data: jobPlacementForm })
    );
  };

  const handleBackClick = () => {
    navigate(`/${ROUTE_JOB_PLACEMENT}/step2`);
  };

  useEffect(() => {
    setDynamicBreadcrumb(BREADCRUMBS_TYPES.JOB_PLACEMENT, isLogin ? 'Upload CV' : 'Log In');
  }, [isLogin]);

  useEffect(() => {
    const filterStep = steps.find((item) => item.id === 2);
    handleClickStep(filterStep);

    if (getCookie(SOCIAL_AUTH_SAVE_DATA)) {
      setTimeout(() => {
        removeCookie(SOCIAL_AUTH_SAVE_DATA);
      }, 1000);
    }
  }, []);

  return (
    <>
      {isLogin ? (
        <JPStepFour />
      ) : (
        <PageInfoCard classList={['center']}>
          <UserFlow
            loginFirst={true}
            title={'Sign In'}
            subTitle={
              'Before saving your job placement info, please login. We do not collect any personal information such as name, address, etc., which could reveal your identity.'
            }
            note={
              'Please login with your DiverseUp account password, or choose to sign in with any of the social networks below. '
            }
            onSignInClick={handleOnSignInClick}
            onSocialButtonClick={handleSocialAuthClick}
          />

          <div className="info-card-content center">
            <div className="buttons-group">
              <Button text={'Go Back'} classList={['white']} onClick={handleBackClick} />
            </div>
          </div>
        </PageInfoCard>
      )}
    </>
  );
};
export default JPStepThree;
