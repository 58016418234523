import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardTitle from '../info-card-title/InfoCardTitle';
import InfoCardValue from '../info-card-value/InfoCardValue';

interface IProps {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const WorkFlexibility: FC<IProps> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle text={'Work Flexibility'} />
      <InfoCardValue
        label={'Flexible hours'}
        value={
          isMyRated
            ? company.work_flexibility_options?.includes('Flexible hours')
              ? 'Yes'
              : 'No'
            : company.flexible_hours
            ? `${company.flexible_hours}% said it’s offered`
            : '—\t'
        }
      />
      <InfoCardValue
        label={'Job sharing'}
        value={
          isMyRated
            ? company.work_flexibility_options?.includes('Job sharing')
              ? 'Yes'
              : 'No'
            : company.job_sharing !== null
            ? company.job_sharing
              ? 'Yes'
              : 'No'
            : '—\t'
        }
      />
      <InfoCardValue
        label={'Working remotely'}
        value={
          isMyRated
            ? company.work_flexibility_options?.includes('Working remotely')
              ? 'Yes'
              : 'No'
            : company.working_remotely
            ? `${company.working_remotely}% said it’s offered`
            : '—\t'
        }
      />
      <InfoCardValue
        label={'Part time opportunity'}
        value={
          isMyRated
            ? company.work_flexibility_options?.includes('Part time opportunity')
              ? 'Yes'
              : 'No'
            : company.part_time_opportunity
            ? `${company.part_time_opportunity}% said it’s offered`
            : '—\t'
        }
      />
    </PageInfoCard>
  );
};

export default WorkFlexibility;
