import React, { FC, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import SearchForm from 'components/shared/forms/search-form/SearchForm';
import AppLogo from 'components/shared/logo/Logo';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import AuthHelpers from 'utils/AuthHelpers';
import { logoutApi } from 'api/user';
import { NAV_LINKS, ROUTE_ROOT, USER_MENU_LINKS } from 'app-constants';
import { INavLink } from 'interfaces';
import styles from './Header.module.scss';
import noAvatar from 'assets/images/no-avatar.png';
import useComponentVisible from 'hooks/use-component-visible.hook';

interface IHeaderProps {
  showSearch: boolean;
}

export const Header: FC<IHeaderProps> = ({ showSearch }) => {
  const { showModal, store } = useGlobalModalContext();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<any>(AuthHelpers.getUserInfo());
  const { setIsLogin, resetStep } = useGlobalJobPlacementContext();
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const location = useLocation();

  useEffect(() => {
    if (store && AuthHelpers.getUserInfo()) {
      setUser(AuthHelpers.getUserInfo());
      setIsLoggedIn(!!AuthHelpers.getUserInfo());
    }
  }, [store]);

  useEffect(() => {
    setUser(AuthHelpers.getUserInfo());
  }, [location]);

  const handleLoginButton = () => {
    !isLoggedIn ? showModal(MODAL_TYPES.SIGN_IN_MODAL) : logout();
  };

  useEffect(() => {
    setIsLoggedIn(!!AuthHelpers.getUserInfo());
  }, [AuthHelpers.getUserInfo()]);

  const logout = () => {
    logoutApi()
      .then(() => {
        AuthHelpers.clearStorage();
        setIsLoggedIn(false);
        setIsLogin(false);
      })
      .catch((error) => {
        if (error.status === 401) {
          AuthHelpers.clearStorage();
          setIsLoggedIn(false);
        }
      });
  };

  return (
    <header className={styles.header}>
      <div className="section">
        <div className={styles.headerWrapper}>
          <Link to={ROUTE_ROOT}>
            <AppLogo />
          </Link>
          <div className={styles.rightPart}>
            <nav className={`${styles.headerMenu} ${showMenu && styles.opened}`}>
              <ul>
                {NAV_LINKS.map((link: INavLink) => {
                  return (
                    <li key={link.id}>
                      <NavLink
                        className={({ isActive }) => {
                          return isActive ? styles.active : '';
                        }}
                        to={link.link}
                        onClick={() => {
                          setShowMenu(false);
                          resetStep();
                        }}
                      >
                        {link.label}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
              <div className={styles.signInButtonsWrapper}>
                {!isLoggedIn ? (
                  <button type="button" className={styles.signInButton} onClick={handleLoginButton}>
                    Sign In
                  </button>
                ) : (
                  <div
                    className={`${styles.userInfo} ${
                      isComponentVisible && styles.userInfoShowMenu
                    }`}
                    ref={ref}
                    onClick={() => setIsComponentVisible(!isComponentVisible)}
                  >
                    <div className={styles.userInfoWrapper}>
                      <span className={styles.userName}>{user?.username || user?.email}</span>
                      <div className={styles.avatar}>
                        <img
                          alt="avatar"
                          src={user?.avatar_link || noAvatar}
                          width="32px"
                          height="32px"
                        />
                      </div>
                    </div>
                    <div className={styles.userMenu}>
                      <ul className={styles.userMenuItems}>
                        {USER_MENU_LINKS.map((link: INavLink) => {
                          return (
                            <li key={link.id}>
                              <NavLink to={link.link} onClick={() => setShowMenu(false)}>
                                {link.label}
                              </NavLink>
                            </li>
                          );
                        })}
                        <li onClick={logout}>Logout</li>
                      </ul>
                    </div>
                  </div>
                )}
                {!isLoggedIn && (
                  <button
                    type="button"
                    className={styles.signUpButton}
                    onClick={() => showModal(MODAL_TYPES.SIGN_UP_MODAL)}
                  >
                    Sign up
                  </button>
                )}
              </div>
            </nav>
            {!showMenu ? (
              <button
                type="button"
                className={styles.mobileBurger}
                onClick={() => setShowMenu(true)}
              />
            ) : (
              <button
                type="button"
                className={styles.mobileBurgerClose}
                onClick={() => setShowMenu(false)}
              />
            )}
          </div>
        </div>
      </div>
      {showSearch && (
        <div className={styles.searchForm}>
          <SearchForm hideOnMobile={true} />
        </div>
      )}
    </header>
  );
};

export default Header;
