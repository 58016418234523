import React, { FC, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import AuthHelpers from '../../utils/AuthHelpers';
import { uploadUserAvatarAPI, updateUserApi } from 'api';
import noAvatar from 'assets/images/no-avatar.png';
import pencilIcon from 'assets/images/icons/pencil.svg';
import styles from './UserProfile.module.scss';
import { useForm } from 'react-hook-form';

export const UserProfile: FC = () => {
  const { showModal, hideModal } = useGlobalModalContext();

  const currentUser = AuthHelpers.getUserInfo();

  const inputFile = useRef(null);
  const [userAvatar, setUserAvatar] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setUserAvatar(currentUser.avatar_link);
    }
  }, [currentUser]);

  const handleOpenChangeNickModal = () => {
    showModal(MODAL_TYPES.CHANGE_NICKNAME_MODAL);
  };
  const handleOpenChangePasswordModal = () => {
    showModal(MODAL_TYPES.CHANGE_PASSWORD_MODAL);
  };

  const onUploadClick = () => {
    /* eslint-disable */
    const target = inputFile?.current! as HTMLElement;
    target && target.click();
  };

  const handleFileInputChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];

    if (!fileObj) {
      return;
    }

    const formData = new FormData();
    formData.append('avatar', fileObj);

    try {
      setIsUploading(true);
      const userAvatarUploadResponse = (await uploadUserAvatarAPI(formData)) as any;
      const response = (await updateUserApi({
        email: currentUser.email,
        avatar_link: userAvatarUploadResponse?.direct_url,
      })) as any;
      //TODO refactor this
      hideModal();
      AuthHelpers.storeUserInfo(response);
      setUserAvatar(response.avatar_link);
      setIsUploading(false);
    } catch (e) {
      setIsUploading(false);
      console.error(e);
    }
  };

  return (
    <section className="section">
      {isUploading && (
        <div className={styles.loadingIcon}>
          <Loader />
        </div>
      )}

      <div
        className={clsx(styles.userProfileContainer, {
          [styles.loadingStatus]: isUploading === true,
        })}
      >
        <div className={clsx(styles.card, styles.profileDetails)}>
          <img className={styles.avatar} src={userAvatar || noAvatar} alt="avatar image" />
          <div>
            <div className={styles.nameSection}>
              {currentUser && currentUser.username && (
                <span className={styles.userName}>{currentUser.username}</span>
              )}
              <div onClick={handleOpenChangeNickModal}>
                <img src={pencilIcon} alt="edit icon" />
                <a className={styles.changeNickName}>Change Nickname</a>
              </div>
            </div>
            {currentUser && <p className={styles.greyText}>{currentUser.email}</p>}
            <button className={styles.button} onClick={onUploadClick}>
              Change profile image
            </button>
            <input type="file" id="file" ref={inputFile} hidden onChange={handleFileInputChange} />
          </div>
        </div>
        <div className={styles.card}>
          <h6 className={styles.subtTtle}>Password</h6>
          <p className={styles.greyText}>
            If you forgot your password you can change it with a new one.
          </p>
          <button className={styles.button} onClick={handleOpenChangePasswordModal}>
            Change Password
          </button>
        </div>
      </div>
    </section>
  );
};

export default UserProfile;
