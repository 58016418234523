export const SECOND_JOB_PLACEMENT_STEP = [
  {
    key: 'important',
    text: 'As a woman when choosing an employer, what is important to you?',
    subText: 'Choose all that apply',
    answers: [
      { text: 'Maternity Leave', value: 'Maternity Leave' },
      { text: 'Working Remotely', value: 'Working Remotely' },
      { text: 'Flexible Hours', value: 'Flexible Hours' },
      { text: 'Mentorship Programs', value: 'Mentorship Programs' },
      { text: 'Compensation', value: 'Compensation' },
      { text: 'Career Development', value: 'Career Development' },
      { text: 'Leadership Opportunities', value: 'Leadership Opportunities' },
      { text: 'No Unconscious Bias', value: 'No Unconscious Bias' },
      { text: 'Onsite Childcare', value: 'Onsite Childcare' },
    ],
  },
  {
    key: 'motivation',
    text: 'What motivates you to do the best job you possibly can?',
    subText: 'Choose all that apply',
    answers: [
      { text: 'Work-Life Balance', value: 'Work-Life Balance' },
      { text: 'Reward Performance', value: 'Reward Performance' },
      { text: 'Corporate Perks', value: 'Corporate Perks' },
      { text: 'Workplace Friendship', value: 'Workplace Friendship' },
      { text: 'Pride in Work', value: 'Pride in Work' },
      { text: 'PTO Flexibility', value: 'PTO Flexibility' },
      { text: 'Open Communication', value: 'Open Communication' },
      { text: 'Team Work', value: 'Team Work' },
      { text: 'Job Security', value: 'Job Security' },
    ],
  },
  {
    key: 'deal_break',
    text: 'What is a deal breaker for you?',
    subText: 'Choose all that apply',
    answers: [
      { text: 'Process Heavy', value: 'Process Heavy' },
      { text: 'Micromanagement', value: 'Micromanagement' },
      { text: 'Lack of Work Flexibility', value: 'Lack of Work Flexibility' },
      { text: 'Too Many Changes', value: 'Too Many Changes' },
      { text: 'Lack of Family Life Balance', value: 'Lack of Family Life Balance' },
      { text: 'Incompetent Leadership', value: 'Incompetent Leadership' },
      { text: 'High Stress', value: 'High Stress' },
      { text: 'Poor Insurance Coverage', value: 'Poor Insurance Coverage' },
      { text: 'Office Politics', value: 'Office Politics' },
      { text: 'None', value: 'None' },
    ],
  },
];
