import React, { FC } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
} from 'react-share';
import style from './ShareSocial.module.scss';

interface BlogProps {
  slug: string;
  title: string;
}

const ShareSocial: FC<BlogProps> = ({ slug, title }) => {
  const baseUrl = window.location.origin;
  return (
    <div className={style.wrapper}>
      <p className={style.title}>SHARE</p>
      <div className={style.links}>
        <FacebookShareButton url={`${baseUrl}/blog/${slug}`} quote={title}>
          <FacebookIcon size={30} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={`${baseUrl}/blog/${slug}`} title={title}>
          <TwitterIcon size={30} round={true} />
        </TwitterShareButton>
        <LinkedinShareButton url={`${baseUrl}/blog/${slug}`} title={title}>
          <LinkedinIcon size={30} round={true} />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default ShareSocial;
