import React, { FC } from 'react';
import styles from './SecondaryBanner.module.scss';
import Button from 'components/shared/button/Button';
import { ROUTE_COMPANY_RATING } from 'app-constants';
import { useNavigate } from 'react-router-dom';

export const SecondaryBanner: FC = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.bannerWrapper}>
      <div className={styles.bannerImage} />
      <div className={styles.infoWrapper}>
        <div className={styles.infoContent}>
          <div className={styles.headerText}>COMPANIES REVIEWS</div>
          <div className={styles.mainText}>Share your personal Experience</div>
          <p className={styles.description}>
            Please share your personal experience regarding your employer. This will help others to
            choose the right company that matches their needs to work with. Our goal is to improve
            the work satisfaction of people like you.
          </p>
          <Button text={'Write a review'} onClick={() => navigate(ROUTE_COMPANY_RATING)} />
        </div>
      </div>
    </div>
  );
};

export default SecondaryBanner;
