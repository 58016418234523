import React, { FC } from 'react';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import RatingStars from 'components/shared/rating-stars/RatingStars';
import Button from 'components/shared/button/Button';
import InfoCardTitle from 'components/shared/page-info-card/components/info-card-title/InfoCardTitle';
import InfoCardValue from 'components/shared/page-info-card/components/info-card-value/InfoCardValue';
import { deleteBusinessRating } from 'api';
import { ROUTE_USER_ADDED_JOB_PLACEMENTS, ROUTE_USER_RATED_COMPANIES } from 'app-constants';
import { useNavigate } from 'react-router-dom';

interface IProps {
  rating: number;
  name: string;
  id: string;
}

export const RemoveRatedCompanyModal: FC<IProps> = ({ name, rating, id }) => {
  const { hideModal } = useGlobalModalContext();

  const navigate = useNavigate();

  const handleDeleteClick = () => {
    deleteBusinessRating({ id }).then(() => {
      hideModal();
      navigate(`/${ROUTE_USER_RATED_COMPANIES}`);
    });
  };

  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <div style={{ width: '100%' }}>
        <InfoCardTitle text={'Are you sure you want to delete this rating?'} />
        <div className="on-remove-section">
          <span className="on-remove-text">Company Name:</span>
          <span className="on-remove-value">{name}</span>
        </div>
        <div className="on-remove-section">
          <span className="on-remove-text">Rating you gave:</span>
          <RatingStars rating={rating || 0} clickable={false} />
        </div>
        <div className="on-remove-buttons">
          <Button text={'Cancel'} classList={['white']} onClick={hideModal} />
          <Button text={'Yes, Delete'} classList={['red', 'smaller']} onClick={handleDeleteClick} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default RemoveRatedCompanyModal;
