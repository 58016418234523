import React, { FC, useState } from 'react';
import Button from 'components/shared/button/Button';
import styles from './InfoBanner.module.scss';

interface IInfoBanner {
  title: string;
  text: string;
  classList?: string[];
  closable?: boolean;
  buttonText?: string;
  buttonClassList?: string[];
  onButtonClick?: () => void;
}

export const InfoBanner: FC<IInfoBanner> = ({
  title,
  text,
  classList = [],
  closable = false,
  buttonText,
  buttonClassList = [],
  onButtonClick,
}) => {
  const [hidden, setHidden] = useState<boolean>(false);

  return (
    <>
      {!hidden && (
        <div className={`${styles.infoBanner} ${classList?.map((item) => styles[item]).join(' ')}`}>
          {closable && <div className={styles.closeBanner} onClick={() => setHidden(true)} />}
          <div className={styles.content}>
            <h3>{title}</h3>
            <span>{text}</span>
            {buttonText && (
              <Button text={buttonText} classList={buttonClassList} onClick={onButtonClick} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default InfoBanner;
