import React, { FC } from 'react';
import styles from './AboutUs.module.scss';

import PageTitle from 'components/common/page-title/PageTitle';

export const AboutUs: FC = () => {
  return (
    <section className="section">
      <div className="section__block no-top-padding">
        <PageTitle title={'About Us'} />

        <div className={styles.aboutUsWrapper}>
          <div className={styles.topSection}>
            <div className={`${styles.aboutUsBlock} ${styles.aboutUsBlockImage}`}>
              <div>
                <h2 className={styles.title}>Our Mission</h2>
                <p className={styles.description}>
                  Utilizing software as a solution to collect targeted data and align wonen’s
                  working preferences to emploers’ offerings.
                </p>
              </div>
            </div>
            <div className={`${styles.aboutUsBlock} ${styles.aboutUsBlockYellow}`}>
              <h2 className={styles.title}>Our Vision</h2>
              <p className={styles.description}>
                Promote succes of working woman through knowledge sharing.
              </p>
            </div>
          </div>
          <div className={styles.aboutUsBlock}>
            <h2 className={styles.title}>Our Reason for Being</h2>
            <p className={styles.description}>
              DiverseUp was founded by two female engineers in Silicon Valley with a passion for
              diversity and inclusion, and a recognition of the unique needs and challenges of being
              a woman in the corporate world. We look for solutions by leveraging data, AI, and
              community input to define and address challenges faced in the workplace, from
              unconscious bias in hiring practices to maternity support by employers.
            </p>
            <p className={styles.description}>
              The DiverseUp platform anonymously collects data focused on gender-equality practices
              from current and past employees, and aggregates it to provide a holistic view on women
              in the workplace. Its intelligent matchmaking algorithm then pairs prospective female
              employees with potential employers based on individual priorities and values to help
              increase retention.
            </p>
            <p className={styles.description}>
              The company aims to bring transparency to workplace practices, measure policies’
              effectiveness, and help the tech sector retain female talent.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
