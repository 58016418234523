import React, { FC } from 'react';
import styles from './Loader.module.scss';

interface ILoader {
  classList?: string[];
}

export const Loader: FC<ILoader> = ({ classList }) => {
  return (
    <div className={`${styles.loaderWrapper} ${classList?.map((item) => styles[item]).join(' ')}`}>
      <div className={styles.ldsRing}>
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
