import PageTitle from 'components/common/page-title/PageTitle';
import StepperComponent from 'components/shared/stepper/Stepper';
import React, { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import { getCookie } from 'utils/AuthHelpers';
import { SOCIAL_AUTH_SAVE_DATA } from 'app-constants';

export const JobPlacementPage: FC = () => {
  const {
    store: { steps },
    resetStep,
  } = useGlobalJobPlacementContext();

  const renderStepperComponent = () => {
    return <StepperComponent steps={steps} />;
  };

  useEffect(() => {
    const data = getCookie(SOCIAL_AUTH_SAVE_DATA);

    if (!data) {
      resetStep();
    }
  }, []);

  return (
    <>
      <PageTitle title={'Job Placement'} />
      {renderStepperComponent()}
      <div className="job-placement-container">
        <Outlet />
      </div>
    </>
  );
};

export default JobPlacementPage;
