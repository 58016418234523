import React, { FC, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import Input from 'components/shared/form-controls/input/Input';
import Button from 'components/shared/button/Button';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import { updateUserApi } from 'api/user';
import AuthHelpers from '../../utils/AuthHelpers';
import styles from 'pages/user-profile/UserProfile.module.scss';

interface IForm {
  username: string;
}

export const ChangeNicknameModal: FC = () => {
  const currentUser = AuthHelpers.getUserInfo();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IForm>({
    defaultValues: {
      username: currentUser?.username,
    },
  });
  const { hideModal } = useGlobalModalContext();
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeUserName: SubmitHandler<IForm> = async ({ username }) => {
    setIsDisabled(true);
    try {
      const response = await updateUserApi({ email: currentUser.email, username: username });
      AuthHelpers.storeUserInfo(response);
      reset();
      hideModal();
    } catch (error: any) {
      setIsDisabled(false);
    }
  };

  const handleCancel = () => {
    reset();
    hideModal();
  };
  return (
    <ModalWindow visible={true} setVisible={hideModal}>
      <h2 className={styles.title}>{'Change your Nickname'}</h2>
      <form className={`form ${styles.form}`} onSubmit={handleSubmit(onChangeUserName)}>
        <Input
          inputLabel="Username/Nickname"
          label="username"
          register={register}
          type="text"
          placeholder="Enter your username/nickname"
          errors={errors}
        />
        <div className={styles.actionButtons}>
          <Button text="Cancel" classList={['white']} onClick={handleCancel} />
          <Button text="Save" classList={['filledRed']} disabled={isDisabled} />
        </div>
      </form>
    </ModalWindow>
  );
};

export default ChangeNicknameModal;
