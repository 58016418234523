import React, { FC } from 'react';
import style from 'styles/modules/Auth.module.scss';
import linkedin from 'assets/images/auth/Linkedin.svg';
import gmail from 'assets/images/auth/Gmail.svg';
import { googleAuthorizeApi, linkedinAuthorizeApi } from 'api';
import { getCookie, setCookie } from 'utils/AuthHelpers';
import { SOCIAL_AUTH_SAVE_DATA } from 'app-constants';

interface ISocialSignInButtons {
  classList?: string[];
  handleSocialButtonClick?: () => void;
}

export const SocialSignInButtons: FC<ISocialSignInButtons> = ({
  classList,
  handleSocialButtonClick,
}) => {
  const linkedInSignIn = () => {
    handleSocialButtonClick && handleSocialButtonClick();
    linkedinAuthorizeApi().then((res) => {
      const data = getCookie(SOCIAL_AUTH_SAVE_DATA);

      if (!data) {
        setCookie(
          SOCIAL_AUTH_SAVE_DATA,
          JSON.stringify({ redirectUrl: window.location.pathname, clearAfterLogin: true })
        );
      }

      window.location.href = res.authorization_url.replace('+r_basicprofile', '');
    });
  };

  const googleSignIn = () => {
    handleSocialButtonClick && handleSocialButtonClick();
    googleAuthorizeApi().then((res) => {
      const data = getCookie(SOCIAL_AUTH_SAVE_DATA);

      if (!data) {
        setCookie(
          SOCIAL_AUTH_SAVE_DATA,
          JSON.stringify({ redirectUrl: window.location.pathname, clearAfterLogin: true })
        );
      }

      window.location.href = res.authorization_url;
    });
  };

  return (
    <div className={`${style.social} ${classList?.map((item) => style[item]).join(' ')}`}>
      <button className={style.linkedin} onClick={linkedInSignIn}>
        <img src={linkedin} alt="linkedin button" />
      </button>
      <button className={style.gmail} onClick={googleSignIn}>
        <img src={gmail} alt="gmail button" />
      </button>
    </div>
  );
};

export default SocialSignInButtons;
