import React, { FC, useEffect, useState } from 'react';
import PageTitle from 'components/common/page-title/PageTitle';
import Cards from 'components/shared/cards/Cards';
import { ICard } from 'interfaces/card';
import { businessesApi, IBusinessesRes, IBusinessReq } from 'api/business';
import { ROUTE_ADD_COMPANY, ROUTE_COMPANY_RATING } from 'app-constants';
import Select, { ISelectOption } from 'components/shared/form-controls/select/Select';
import { useForm } from 'react-hook-form';
import { Pagination } from 'interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { getParams } from 'utils/http.utils';
import Button from 'components/shared/button/Button';
import AuthHelpers from 'utils/AuthHelpers';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';

interface IForm {
  sort: any;
}

export const CompanyList: FC = () => {
  const [businesses, setBusinesses] = useState<ICard[] | []>([]);
  const [pagination, setPagination] = useState<Pagination>();
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(true);

  const { hideModal, showModal } = useGlobalModalContext();

  const filterOptions: ISelectOption[] = [
    { label: 'Company Name (A-Z)', value: { sort_by: 'name', order: 'asc' } },
    { label: 'Company Name (Z-A)', value: { sort_by: 'name', order: 'desc' } },
    { label: 'Company Rating (Low to High)', value: { sort_by: 'rating', order: 'asc' } },
    { label: 'Company Rating (High to Low)', value: { sort_by: 'rating', order: 'desc' } },
    { label: 'Rating Date (New to Old)', value: { sort_by: 'ratingDate', order: 'desc' } },
    { label: 'Rating Date (Old to New)', value: { sort_by: 'ratingDate', order: 'asc' } },
  ];

  const { control, watch, setValue } = useForm<IForm>({
    defaultValues: {
      sort: filterOptions[4].value,
    },
  });
  const watchSort = watch('sort', false);

  const location = useLocation();

  const navigation = useNavigate();

  useEffect(() => {
    const params = getParams();
    setBusinesses([]);
    setIsBusinessLoading(true);
    if (watchSort) {
      getBusinesses({ page: 1, size: 12, ...params, ...watchSort });
    }
  }, [watchSort]);

  useEffect(() => {
    setValue('sort', filterOptions[4].value);
  }, [location]);

  const handleAddCompanyClick = () => {
    navigation(`/${ROUTE_COMPANY_RATING}/${ROUTE_ADD_COMPANY}`);
  };

  const getBusinesses = (params: IBusinessReq) => {
    businessesApi(params).then((res) => {
      setBusinesses(mapBusinessResponse(res));
    });
  };

  const mapBusinessResponse = (res: IBusinessesRes): ICard[] => {
    const newData: ICard[] | [] = res.items?.map((el) => ({
      type: 'company',
      description: el.short_description || '',
      imageUrl: el.logo || '',
      link: `/${ROUTE_COMPANY_RATING}/${el.slug}`,
      rating: el.rating || 0,
      title: el.name,
    }));

    setPagination({ total: res.total, page: res.page, size: res.size });
    setIsBusinessLoading(false);

    return newData;
  };

  return (
    <div className="section__block no-top-padding">
      <PageTitle title={'Company Rating'} />
      <div className="flexWrapper flexWrapper--align-center space-between">
        <div className="filters">
          <form className="form">
            <Select
              options={filterOptions}
              control={control}
              name={'sort'}
              selectLabel={'Sort by:'}
              selectPlaceholder={'Select the option'}
            />
          </form>
        </div>
        <Button
          text={'Add a Company'}
          classList={['red', 'withPlus']}
          onClick={handleAddCompanyClick}
        />
      </div>
      <Cards
        cards={businesses}
        button={null}
        pagination={pagination}
        isLoading={isBusinessLoading}
      />
    </div>
  );
};

export default CompanyList;
