import { Navigate } from 'react-router-dom';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import { useEffect } from 'react';

export const ProtectedRoute = ({ redirectUrl, children }) => {
  const {
    store: { isLogin },
  } = useGlobalJobPlacementContext();

  if (!isLogin) {
    return <Navigate to={redirectUrl} replace />;
  }

  return children;
};
