import React, { FC } from 'react';
import styles from './JobPlacementCard.module.scss';
import InfoCardTitle from 'components/shared/page-info-card/components/info-card-title/InfoCardTitle';
import InfoCardValue from 'components/shared/page-info-card/components/info-card-value/InfoCardValue';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import Button from 'components/shared/button/Button';
import { useNavigate } from 'react-router-dom';
import deleteIcon from 'assets/images/icons/delete.svg';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import { TReviewLabel } from 'components/shared/review-label/ReviewLabel';

interface IJobPlacementCard {
  id: string;
  status: TReviewLabel;
  current_job_title: string;
  current_job_category: string;
  created: string;
}

export const JobPlacementCard: FC<IJobPlacementCard> = ({
  id,
  status,
  current_job_category,
  current_job_title,
  created,
}) => {
  const navigate = useNavigate();
  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();
  const { showModal } = useGlobalModalContext();

  const showDeleteModal = () => {
    showModal(MODAL_TYPES.DELETE_ADDED_JOB_PLACEMENT_MODAL, {
      id,
      current_job_category,
      current_job_title,
      created: normalizeDate(created),
    });
  };

  const onClickEdit = () => {
    setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, `Request №${id}`);
    navigate(id);
  };

  const normalizeDate = (date) => {
    const newDate = new Date(date);

    return newDate.toLocaleDateString('en-US');
  };

  return (
    <PageInfoCard classList={['no-margin-top']}>
      <InfoCardTitle text={`Job Placement Request №${id}`} reviewLabel={status} />
      <InfoCardValue label={'Job Title'} value={current_job_title} classList={['fullWidth']} />
      <InfoCardValue
        label={'Job Function'}
        value={current_job_category}
        classList={['fullWidth']}
      />
      <InfoCardValue
        label={'Request Date'}
        value={normalizeDate(created)}
        classList={['fullWidth']}
      />
      <div className={`flexWrapper ${styles.buttons}`}>
        <Button text={'Edit'} classList={['borderedRed']} onClick={onClickEdit} />
        <Button text={''} type={'icon'} iconLink={deleteIcon} onClick={showDeleteModal} />
      </div>
    </PageInfoCard>
  );
};
