import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import { getMyBusinessRatingBlyIdApi, IBusinessItemInfo } from 'api/business';
import Loader from 'components/common/loader/Loader';
import AnythingAbout from 'components/shared/page-info-card/components/anything-important/AnythingImportant';
import EqualOpportunity from 'components/shared/page-info-card/components/equal-opportunity/EqualOpportunity';
import LeastLike from 'components/shared/page-info-card/components/least-like/LeastLike';
import MaternityLeave from 'components/shared/page-info-card/components/maternity-leave/MaternityLeave';
import MostLike from 'components/shared/page-info-card/components/most-like/MostLike';
import MotherhoodSupport from 'components/shared/page-info-card/components/motherhood-support/MotherhoodSupport';
import OnScale from 'components/shared/page-info-card/components/on-scale/OnScale';
import WorkFlexibility from 'components/shared/page-info-card/components/work-flexibility/WorkFlexibility';

const RatedDetails = () => {
  const [company, setCompany] = useState<IBusinessItemInfo>({} as IBusinessItemInfo);
  const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(true);
  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();

  const location = useLocation();
  const { slug } = useParams();

  const loadCompanyData = () => {
    setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, '');
    getMyBusinessRatingBlyIdApi({ id: slug || '' }).then((res) => {
      setCompany({
        ...company,
        ...res,
        short_description: res.other_notes_about_company,
        paid_leave: res.paid_maternity_weeks,
        unpaid_leave: res.unpaid_maternity_weeks,
        motherhood_supports: res.motherhood_support_options,
        most_like_top_three: res.like_most_about_company_options,
        least_like_top_three: res.like_least_about_company_options,
      });
      setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, `Rating №${slug}`);
      setIsCompanyLoading(false);
    });
  };

  useEffect(() => {
    setIsCompanyLoading(true);
    loadCompanyData();
  }, [location]);

  return (
    <>
      {!company || isCompanyLoading ? (
        <div className="flexWrapper flexWrapper--center">
          <Loader />
        </div>
      ) : (
        <div className="section__block no-top-padding no-bottom-padding">
          <EqualOpportunity company={company} isMyRated={true} />
          <MaternityLeave company={company} isMyRated={true} />
          <MotherhoodSupport company={company} isMyRated={true} />
          <WorkFlexibility company={company} isMyRated={true} />
          <MostLike company={company} isMyRated={true} />
          <LeastLike company={company} isMyRated={true} />
          <OnScale company={company} isMyRated={true} />
          <AnythingAbout company={company} isMyRated={true} />
        </div>
      )}
    </>
  );
};

export default RatedDetails;
