import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardRating from '../info-card-rating/InfoCardRating';
import InfoCardTitle from '../info-card-title/InfoCardTitle';

interface IEqual {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const EqualOpportunity: FC<IEqual> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle text={'Equal Opportunity for men and women:'} />
      <InfoCardRating
        rating={company.rate_sponsorship_mentorship_opportunity || 0}
        question={'Opportunity for sponsorship and mentorship program?'}
      />
      <InfoCardRating
        rating={company.rate_equal_treatment_of_men_and_women || 0}
        question={'Equal treatment of men and women?'}
      />
      <InfoCardRating
        rating={company.rate_equal_pay_for_equal_performance || 0}
        question={'Equal pay for equal performance?'}
      />
      <InfoCardRating
        rating={company.rate_equal_opportunities_to_move_up_organization || 0}
        question={'Equal opportunity to move up in the organization?'}
      />
    </PageInfoCard>
  );
};

export default EqualOpportunity;
