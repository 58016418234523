import React, { FC, useEffect, useState } from 'react';
import Cards from 'components/shared/cards/Cards';
import { ICard } from 'interfaces';
import { getMyBusinessRatingApi } from 'api';
import { ROUTE_COMPANY_RATING } from 'app-constants';
import styles from './UserRatedCompaniesPage.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { MODAL_TYPES, useGlobalModalContext } from 'contexts/GlobalModalContext';
import InfoBanner from 'components/shared/info-banner/InfoBanner';

export const UserRatedCompaniesPage: FC = () => {
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(true);
  const [businesses, setBusinesses] = useState<ICard[] | []>([]);
  const { showModal } = useGlobalModalContext();

  const navigate = useNavigate();
  const location = useLocation();

  const onView = (slug: string) => {
    navigate(slug);
  };

  const handleRateCompanyButtonClick = () => {
    navigate(`/${ROUTE_COMPANY_RATING}`);
  };

  useEffect(() => {
    setIsBusinessLoading(true);

    getMyBusinessRatingApi().then((res) => {
      const newData: ICard[] | [] = res.items?.map((el) => ({
        type: 'company',
        description: el.business.short_description || '',
        imageUrl: el.business.logo || '',
        link: `/${ROUTE_COMPANY_RATING}/${el.business_slug}`,
        rating: el.my_rating,
        title: el.business.name,
        showLearnMoreBtn: false,
        onView: () => onView(el.id),
        onDelete: () =>
          showModal(MODAL_TYPES.REMOVE_RATED_COMPANY_MODAL, {
            id: el.id,
            name: el.business.name,
            rating: el.my_rating,
          }),
      }));

      setBusinesses(newData || []);
      setIsBusinessLoading(false);
    });
  }, [location]);

  return (
    <div className={styles.wrapper}>
      {isBusinessLoading || businesses.length > 0 ? (
        <Cards cards={businesses} isLoading={isBusinessLoading} />
      ) : (
        <>
          {!isBusinessLoading && (
            <InfoBanner
              title={`You didn’t rate any Company yet`}
              text={
                'Add your first rating for Company, so it will be visible on our website and other people can find your opinion about company'
              }
              classList={['yellow']}
              buttonText={'Add rating'}
              buttonClassList={['withPlus']}
              onButtonClick={handleRateCompanyButtonClick}
            />
          )}
        </>
      )}
    </div>
  );
};

export default UserRatedCompaniesPage;
