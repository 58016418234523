import axios from 'utils/axios.config';

export const getCountries = (): Promise<string[]> => {
  return axios.get('/api/countries');
};

export const getJobCategories = (): Promise<string[]> => {
  return axios.get('/api/jobCategories');
};

export const getIndustries = (): Promise<string[]> => {
  return axios.get('/api/industries');
};
