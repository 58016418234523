import { IBusinessItemInfo } from 'api';
import JobTrend from 'components/shared/job-trend/JobTrend';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardTitle from '../info-card-title/InfoCardTitle';

interface IProps {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const MostLike: FC<IProps> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle text={`What women most like about ${company.name}`} />
      <div className={'flexWrapper'}>
        {company.most_like_top_three?.length
          ? company.most_like_top_three.map((item, index) => {
              return <JobTrend key={index} text={item} />;
            })
          : '—\t'}
      </div>
    </PageInfoCard>
  );
};

export default MostLike;
