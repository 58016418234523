import React, { FC } from 'react';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import successImage from 'assets/images/icons/checked.svg';
import Button from 'components/shared/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTE_COMPANY_RATING, ROUTE_USER_ADDED_COMPANIES } from 'app-constants';

export const CompanyOnReviewModal: FC = () => {
  const { hideModal } = useGlobalModalContext();

  const navigation = useNavigate();

  const handleCloseModal = () => {
    navigation(`/${ROUTE_USER_ADDED_COMPANIES}`);
    hideModal();
  };

  return (
    <ModalWindow visible={true} setVisible={handleCloseModal}>
      <div className="on-review-wrapper">
        <img src={successImage} alt="success" width="80px" height="80px" />
        <h3>Your Request is sent!</h3>
        <span>
          Our moderator will review your request and we will let you know once your company will be
          posted. You can check out your added companies inside your User Profile.
        </span>
        <Button text={'Got it!'} classList={['red']} onClick={handleCloseModal} />
      </div>
    </ModalWindow>
  );
};

export default CompanyOnReviewModal;
