import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Stepper.module.scss';

interface StepModel {
  id: number;
  name: string;
  route: string;
  isActivated: boolean;
}
interface IStepperProps {
  steps: StepModel[];
  onClick?: (step: StepModel) => void;
  disabled?: boolean;
}

const StepperComponent: FC<IStepperProps> = ({ steps, onClick, disabled = false }) => {
  const [activeKey, setActiveKey] = useState(0);

  useEffect(() => {
    const lastActiveStep = steps.map((i) => i.isActivated).lastIndexOf(true);
    setActiveKey(lastActiveStep >= 0 ? lastActiveStep : 0);
  }, [steps]);

  return (
    <div className={styles.stepperWrapper}>
      <ul className={styles.stepList}>
        {steps.map((step, index) => {
          return (
            <li
              className={`${location.pathname == step.route ? styles.active : ''} ${
                activeKey > index || step.isActivated ? styles.completed : ''
              }`}
              key={index}
              onClick={() => {
                if (!disabled && activeKey + 1 > index) {
                  onClick?.(step);
                  setActiveKey(index);
                }
              }}
            >
              {!disabled && activeKey + 1 > index ? (
                <Link to={!disabled ? step.route : '#'}>
                  <span>{index + 1}</span>
                  <h6>{step.name}</h6>
                </Link>
              ) : (
                <Link to="#" onClick={(event) => event.preventDefault()}>
                  <span>{index + 1}</span>
                  <h6>{step.name}</h6>
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default StepperComponent;
