import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardTitle from '../info-card-title/InfoCardTitle';

interface IEqual {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const AnythingAbout: FC<IEqual> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle
        text={`Anything else important for others to know about ${company.name}?`}
        subText={company.short_description ? company.short_description : '—\t'}
      />
    </PageInfoCard>
  );
};

export default AnythingAbout;
