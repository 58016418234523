import React from 'react';

import {
  ROUTE_ABOUT_US,
  ROUTE_ADD_RATING,
  ROUTE_BLOG,
  ROUTE_COMPANY_RATING,
  ROUTE_EMPLOYER,
  ROUTE_JOB_PLACEMENT,
  ROUTE_ROOT,
  ROUTE_TERMS_OF_USE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_POSTING_GUIDELINES,
  ROUTE_USER_PROFILE,
  ROUTE_USER_ADDED_COMPANIES,
  ROUTE_ADD_COMPANY,
  ROUTE_USER_ADDED_JOB_PLACEMENTS,
  ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_GENERAL,
  ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_CULTURE,
  ROUTE_USER_RATED_COMPANIES,
} from 'app-constants';
import HomePage from 'pages/home/Home';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import CompanyRating from 'pages/company-rating/CompanyRating';
import JobPlacement from 'pages/job-placement/JobPlacement';
import Blog from 'pages/blog/Blog';
import Employer from 'pages/employer/Employer';
import AboutUs from 'pages/about-us/AboutUs';
import CompanyInfoPage from 'pages/company-rating/sub-pages/company-info/CompanyInfoPage';
import CompanyList from 'pages/company-rating/sub-pages/company-list/CompanyList';
import CompanyInfo from 'pages/company-rating/sub-pages/company-info/sub-pages/company-info-page/CompanyInfo';
import AddRatingStepOne from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/steps/AddRatingStepOne';
import AddRatingStepTwo from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/steps/AddRatingStepTwo';
import AddRatingStepThree from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/steps/AddRatingStepThree';
import AddRatingStepFour from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/steps/AddRatingStepFour';
import AddRatingStepFive from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/steps/AddRatingStepFive';
import JPStepOne from 'pages/job-placement/sub-pages/StepOne';
import JPStepTwo from 'pages/job-placement/sub-pages/StepTwo';
import JPStepThree from 'pages/job-placement/sub-pages/StepThree';
import AddRating from 'pages/company-rating/sub-pages/company-info/sub-pages/add-rating/AddRating';
import BlogInfoPage from 'pages/blog/sub-pages/blog-info/BlogInfoPage';
import BlogList from 'pages/blog/sub-pages/blog-list/BlogList';
import TermsOfUse from 'pages/terms-of-use/TermsOfUse';
import PrivacyPolicy from 'pages/privacy-policy/PrivacyPolicy';
import PostingGuidelines from 'pages/posting-guidelines/PostingGuidelines';
import { useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import NewPasswordModal from './components/modals/NewPasswordModal';
import GoogleCallback from 'pages/callbacks/google-callback/GoogleCallback';
import LinkedInCallback from 'pages/callbacks/linkedin-callback/LinkedInCallback';
import JPStepFive from 'pages/job-placement/sub-pages/StepFive';
import UserProfile from 'pages/user-profile/UserProfile';
import AddCompany from 'pages/company-rating/sub-pages/add-company/AddCompany';
import UserAddedCompanies from 'pages/user-added-companies/UserAddedCompanies';
import { ProtectedRoute } from 'components/common/protected-route/ProtectedRoute';
import { UserAddedJobPlacement } from 'pages/user-added-job-placements/UserAddedJobPlacement';
import JobPlacementInfo from 'pages/user-added-job-placements/pages/job-placement-info/JobPlacementInfo';
import JobPlacementList from 'pages/user-added-job-placements/pages/job-placement-list/JobPlacementList';
import UserRatedCompanies from 'pages/user-rated-companies/UserRatedCompanies';
import UserRatedCompaniesPage from 'pages/user-rated-companies/sub-pages/UserRatedCompaniesPage';
import RatedDetails from 'pages/user-rated-companies/sub-pages/rated-detail/RatedDetails';

const ContextBreadcrumb = () => {
  const { store } = useDynamicBreadcrumbContext();

  return <span>{store.props}</span>;
};

export const ROUTES: BreadcrumbsRoute[] = [
  {
    path: ROUTE_ROOT,
    element: <HomePage />,
    breadcrumb: null,
  },
  {
    path: '/reset_password',
    breadcrumb: null,
    element: <NewPasswordModal />,
  },
  {
    path: '/google',
    breadcrumb: null,
    children: [{ path: 'callback', element: <GoogleCallback />, breadcrumb: null }],
  },
  {
    path: '/linkedin',
    breadcrumb: null,
    children: [{ path: 'callback', element: <LinkedInCallback />, breadcrumb: null }],
  },
  {
    path: ROUTE_COMPANY_RATING,
    element: <CompanyRating />,
    breadcrumb: 'Company Rating',
    children: [
      { index: true, element: <CompanyList /> },
      {
        path: ':slug',
        element: <CompanyInfoPage />,
        children: [
          {
            path: '',
            element: <CompanyInfo />,
            breadcrumb: ContextBreadcrumb,
          },
          {
            path: ROUTE_ADD_RATING,
            element: <AddRating />,
            breadcrumb: null,
            children: [
              { index: true, element: <AddRatingStepOne /> },
              { path: 'step2', element: <AddRatingStepTwo />, breadcrumb: 'Equal opportunity' },
              { path: 'step3', element: <AddRatingStepThree />, breadcrumb: 'Family Support' },
              { path: 'step4', element: <AddRatingStepFour />, breadcrumb: 'Company Culture' },
              { path: 'step5', element: <AddRatingStepFive />, breadcrumb: 'Your profile' },
            ],
          },
        ],
      },
      {
        path: ROUTE_ADD_COMPANY,
        element: <AddCompany />,
      },
    ],
  },
  {
    path: ROUTE_JOB_PLACEMENT,
    element: <JobPlacement />,
    breadcrumb: 'Job Placement',
    children: [
      { index: true, element: <JPStepOne /> },
      { path: 'step2', element: <JPStepTwo />, breadcrumb: 'Cultural Preferences' },
      { path: 'step3', element: <JPStepThree />, breadcrumb: ContextBreadcrumb },
      { path: 'step5', element: <JPStepFive />, breadcrumb: 'Notifications' },
    ],
  },
  {
    path: ROUTE_BLOG,
    element: <Blog />,
    breadcrumb: 'Blog',
    children: [
      { index: true, element: <BlogList /> },
      {
        path: ':slug',
        element: <BlogInfoPage />,
        breadcrumb: ContextBreadcrumb,
      },
    ],
  },
  { path: ROUTE_EMPLOYER, element: <Employer />, breadcrumb: null },
  { path: ROUTE_ABOUT_US, element: <AboutUs />, breadcrumb: 'About Us' },
  { path: ROUTE_TERMS_OF_USE, element: <TermsOfUse />, breadcrumb: 'Terms Of Use' },
  { path: ROUTE_PRIVACY_POLICY, element: <PrivacyPolicy />, breadcrumb: 'Privacy Policy' },
  {
    path: ROUTE_POSTING_GUIDELINES,
    element: <PostingGuidelines />,
    breadcrumb: 'Posting Guidelines',
  },
  {
    path: ROUTE_USER_PROFILE,
    element: (
      <ProtectedRoute redirectUrl={ROUTE_ROOT}>
        <UserProfile />
      </ProtectedRoute>
    ),
    breadcrumb: 'User Profile',
  },
  {
    path: ROUTE_USER_ADDED_COMPANIES,
    element: (
      <ProtectedRoute redirectUrl={ROUTE_ROOT}>
        <UserAddedCompanies />
      </ProtectedRoute>
    ),
    breadcrumb: 'Added Companies',
  },
  {
    path: ROUTE_USER_ADDED_JOB_PLACEMENTS,
    element: (
      <ProtectedRoute redirectUrl={ROUTE_ROOT}>
        <UserAddedJobPlacement />
      </ProtectedRoute>
    ),
    breadcrumb: 'Job Placement',
    children: [
      {
        index: true,
        element: <JobPlacementList />,
        breadcrumb: null,
      },
      {
        path: ':id',
        element: <JobPlacementInfo />,
        breadcrumb: ContextBreadcrumb,
        children: [
          {
            path: ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_GENERAL,
            element: <JPStepOne />,
            breadcrumb: 'Edit General Information',
          },
          {
            path: ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_CULTURE,
            element: <JPStepTwo />,
            breadcrumb: 'Edit Culture Preferences',
          },
        ],
      },
    ],
  },
  {
    path: ROUTE_USER_RATED_COMPANIES,
    element: (
      <ProtectedRoute redirectUrl={ROUTE_ROOT}>
        <UserRatedCompanies />
      </ProtectedRoute>
    ),
    breadcrumb: 'Rated Companies',
    children: [
      { index: true, element: <UserRatedCompaniesPage /> },
      {
        path: ':slug',
        element: <RatedDetails />,
        breadcrumb: ContextBreadcrumb,
      },
    ],
  },
];

export default ROUTES;
