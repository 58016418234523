import { ROUTE_JOB_PLACEMENT, ROUTE_USER_ADDED_JOB_PLACEMENTS } from 'app-constants';
import Button from 'components/shared/button/Button';
import Checkbox from 'components/shared/form-controls/checkbox/Checkbox';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import {
  JOB_EMPLOYMENT_STATUS,
  JOB_OPPORTUNITY_TYPE,
  JOB_WORK_PREFERENCE,
  SELECTED_COMMUTE_OPTIONS,
  SELECTED_TRAVEL_OPTIONS,
  SELECTED_WORK_FLEXIBILITY,
} from './data/Step.data';
import RadioButtonGroup from 'components/shared/form-controls/radio-button-group/RadioButtonGroup';
import Input from 'components/shared/form-controls/input/Input';
import Select, { ISelectOption } from 'components/shared/form-controls/select/Select';
import { getCountries, getJobCategories, patchJobPlacement } from 'api';

export const JPStepOne: FC = () => {
  const {
    store: { jobPlacementForm, steps },
    setJobPlacementForm,
    handleClickStep,
    resetStep,
  } = useGlobalJobPlacementContext();

  const context = useOutletContext<any>();

  const {
    register,
    control,
    getValues,
    reset,
    formState: { errors, isValid },
    watch,
  } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      current_employment_status: jobPlacementForm.current_employment_status || '',
      current_job_title: jobPlacementForm.current_job_title || '',
      current_job_category: jobPlacementForm.current_job_category || '',
      current_country: jobPlacementForm.current_country || '',
      current_zip_code_or_city: jobPlacementForm.current_zip_code_or_city || '',
      selected_job_title: jobPlacementForm.selected_job_title || '',
      selected_job_category: jobPlacementForm.selected_job_category || '',
    },
  });

  const watchAllFields = watch();
  const [countries, setCountries] = useState<ISelectOption[]>([]);
  const [jobCategories, setJobCategories] = useState<ISelectOption[]>([]);
  const [disabledButton, setDisabledButton] = useState<boolean>(true);

  useEffect(() => {
    getCountries().then((res) => {
      const data: ISelectOption[] = res.map((item) => {
        return { label: item, value: item };
      });

      setCountries(data);
    });

    getJobCategories().then((res) => {
      const data: ISelectOption[] = res.map((item) => {
        return { label: item, value: item };
      });

      setJobCategories(data);
    });

    if (context) {
      reset({
        current_employment_status: context.jobPlacementInfo.current_employment_status,
        current_job_title: context.jobPlacementInfo.current_job_title || '',
        current_job_category: context.jobPlacementInfo.current_job_category || '',
        current_country: context.jobPlacementInfo.current_country || '',
        current_zip_code_or_city: context.jobPlacementInfo.current_zip_code_or_city || '',
        selected_job_title: context.jobPlacementInfo.selected_job_title || '',
        selected_job_category: context.jobPlacementInfo.selected_job_category || '',
      });
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const isInvalid =
      jobPlacementForm.selected_job_opportunity_type.length === 0 ||
      jobPlacementForm.selected_work_flexibility.length === 0 ||
      jobPlacementForm.selected_commute_options.length === 0 ||
      jobPlacementForm.selected_travel_options.length === 0 ||
      jobPlacementForm.selected_work_preference.length === 0 ||
      !isValid;
    setDisabledButton(isInvalid);
  }, [watchAllFields, jobPlacementForm]);

  const handleOnChangeCheckBoxSelectedJob = (item, value: boolean) => {
    if (value) {
      setJobPlacementForm({
        selected_job_opportunity_type: [
          ...jobPlacementForm.selected_job_opportunity_type,
          item.label,
        ],
      });
    } else {
      const filterSelected = jobPlacementForm.selected_job_opportunity_type.filter(
        (typFilter: any) => typFilter !== item.label
      );
      setJobPlacementForm({
        selected_job_opportunity_type: filterSelected,
      });
    }
  };

  const handleOnChangeCheckBoxWorkPreference = (item, value: boolean) => {
    if (value) {
      setJobPlacementForm({
        selected_work_preference: [...jobPlacementForm.selected_work_preference, item.label],
      });
    } else {
      const filterSelected = jobPlacementForm.selected_work_preference.filter(
        (typFilter: any) => typFilter !== item.label
      );
      setJobPlacementForm({
        selected_work_preference: filterSelected,
      });
    }
  };

  const handleOnChangeCheckBoxFlexibility = (item, value: boolean) => {
    if (value) {
      setJobPlacementForm({
        selected_work_flexibility: [...jobPlacementForm.selected_work_flexibility, item.label],
      });
    } else {
      const filterSelected = jobPlacementForm.selected_work_flexibility.filter(
        (typFilter: any) => typFilter !== item.label
      );
      setJobPlacementForm({
        selected_work_flexibility: filterSelected,
      });
    }
  };

  const handleOnChangeCheckBoxCommuteOption = (item, value: boolean) => {
    if (value) {
      setJobPlacementForm({
        selected_commute_options: [...jobPlacementForm.selected_commute_options, item.title],
      });
    } else {
      const filterSelected = jobPlacementForm.selected_commute_options.filter(
        (typFilter: any) => typFilter !== item.title
      );
      setJobPlacementForm({
        selected_commute_options: filterSelected,
      });
    }
  };

  const handleOnChangeCheckBoxTravelOption = (item, value: boolean) => {
    if (value) {
      setJobPlacementForm({
        selected_travel_options: [...jobPlacementForm.selected_travel_options, item.title],
      });
    } else {
      const filterSelected = jobPlacementForm.selected_travel_options.filter(
        (typFilter: any) => typFilter !== item.title
      );
      setJobPlacementForm({
        selected_travel_options: filterSelected,
      });
    }
  };

  const handleClickNext = () => {
    if (disabledButton) return;
    setJobPlacementForm(getJobPlacementFormValuesFromForm());
    navigate(`/${ROUTE_JOB_PLACEMENT}/step2`);
  };

  const getJobPlacementFormValuesFromForm = () => {
    return {
      current_employment_status: getValues('current_employment_status'),
      current_job_title: getValues('current_job_title'),
      current_job_category: getValues('current_job_category'),
      current_country: getValues('current_country'),
      current_zip_code_or_city: getValues('current_zip_code_or_city'),
      selected_job_title: getValues('selected_job_title'),
      selected_job_category: getValues('selected_job_category'),
    };
  };

  const handleCancelEdit = () => {
    navigate(`/${ROUTE_USER_ADDED_JOB_PLACEMENTS}/${context.jobPlacementInfo.id}`);
  };

  const handleUpdateJobPlacement = () => {
    if (disabledButton) return;
    const requestData = {
      ...jobPlacementForm,
      ...getJobPlacementFormValuesFromForm(),
    };

    patchJobPlacement(context.jobPlacementInfo.id, requestData).then(() => {
      resetStep();
      navigate(`/${ROUTE_USER_ADDED_JOB_PLACEMENTS}/${context.jobPlacementInfo.id}`);
    });
  };

  useEffect(() => {
    const filterStep = steps.find((item) => item.id === 0);
    handleClickStep(filterStep);
  }, []);

  return (
    <div className="ls-multi-form">
      <h3 className="ls-form-heading">Tell us about your Job Preferences</h3>

      <div className="check-item-field">
        <h4 className="input-heading">What is your current employment status?</h4>
        <div className="form vertical">
          <div className="form-group">
            <RadioButtonGroup
              label={'current_employment_status'}
              register={register}
              radioButtons={JOB_EMPLOYMENT_STATUS}
              defaultSelected={
                getValues('current_employment_status') || JOB_EMPLOYMENT_STATUS[0].value
              }
            />
          </div>
        </div>
      </div>

      <div className="check-item-field">
        <div className="form">
          <div className="double-form-group">
            <Input
              inputLabel="What is your current job title?"
              label="current_job_title"
              register={register}
              required={true}
              type="text"
              placeholder="Enter your current job title"
            />
            <div className="splitter" />
            <Select
              selectLabel="What is your current job function?"
              control={control}
              name={'current_job_category'}
              required={true}
              selectPlaceholder="Choose your industry"
              options={jobCategories}
            />
          </div>
        </div>
      </div>

      <div className="check-item-field">
        <div className="form">
          <div className="double-form-group">
            <Select
              selectLabel="What is your country?"
              control={control}
              name={'current_country'}
              required={true}
              selectPlaceholder="Choose your country"
              options={countries}
            />
            <div className="splitter" />
            <Input
              inputLabel={
                getValues('current_country') === 'United States'
                  ? 'What is your ZIP Code?'
                  : 'What is your city?'
              }
              label="current_zip_code_or_city"
              register={register}
              required={false}
              type="text"
              placeholder={
                getValues('current_country') === 'United States'
                  ? 'What is your ZIP Code?'
                  : 'What is your city?'
              }
            />
          </div>
        </div>
      </div>

      <div className="check-item-field">
        <h4 className="input-heading">What kind of job opportunity are you looking for?</h4>
        <ul>
          {JOB_OPPORTUNITY_TYPE.map((item) => (
            <li key={item.id}>
              <Checkbox
                label={`opportunity-${item.label}`}
                checkboxLabel={item.title}
                register={register}
                onChange={(value: boolean) => handleOnChangeCheckBoxSelectedJob(item, value)}
                checked={jobPlacementForm.selected_job_opportunity_type.includes(item.label)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="check-item-field">
        <h4 className="input-heading">What is your work preference?</h4>
        <ul>
          {JOB_WORK_PREFERENCE.map((item) => (
            <li key={item.id}>
              <Checkbox
                label={`work-preference-${item.label}`}
                checkboxLabel={item.title}
                register={register}
                onChange={(value: boolean) => handleOnChangeCheckBoxWorkPreference(item, value)}
                checked={jobPlacementForm.selected_work_preference.includes(item.label)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="check-item-field">
        <div className="form">
          <div className="double-form-group">
            <Input
              inputLabel="What is a job title you are looking for?"
              label="selected_job_title"
              register={register}
              required={true}
              type="text"
              placeholder="Enter your current job title"
            />
            <div className="splitter" />
            <Select
              selectLabel="What is a job function you are interested in?"
              control={control}
              name={'selected_job_category'}
              required={true}
              selectPlaceholder="Choose an industry"
              options={jobCategories}
            />
          </div>
        </div>
      </div>

      <div className="check-item-field">
        <h4 className="input-heading">
          Which of the following work flexibility options are you interested in?
        </h4>
        <ul>
          {SELECTED_WORK_FLEXIBILITY.map((item) => (
            <li key={item.id}>
              <Checkbox
                label={item.label}
                checkboxLabel={item.title}
                register={register}
                onChange={(value: boolean) => handleOnChangeCheckBoxFlexibility(item, value)}
                checked={jobPlacementForm.selected_work_flexibility.includes(item.label)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="check-item-field">
        <h4 className="input-heading">How much commuting are you willing to do for you job?</h4>
        <ul>
          {SELECTED_COMMUTE_OPTIONS.map((item) => (
            <li key={item.id}>
              <Checkbox
                label={item.label}
                checkboxLabel={item.title}
                register={register}
                onChange={(value: boolean) => handleOnChangeCheckBoxCommuteOption(item, value)}
                checked={jobPlacementForm.selected_commute_options.includes(item.title)}
              />
            </li>
          ))}
        </ul>
      </div>

      <div className="check-item-field">
        <h4 className="input-heading">How much travel are you willing to do for you job?</h4>
        <ul>
          {SELECTED_TRAVEL_OPTIONS.map((item) => (
            <li key={item.id}>
              <Checkbox
                label={item.label}
                checkboxLabel={item.title}
                register={register}
                onChange={(value: boolean) => handleOnChangeCheckBoxTravelOption(item, value)}
                checked={jobPlacementForm.selected_travel_options.includes(item.title)}
              />
            </li>
          ))}
        </ul>
      </div>
      <div className="step-buttons">
        {!context ? (
          <Button text={'Next Step'} onClick={handleClickNext} disabled={disabledButton} />
        ) : (
          <>
            <Button
              text="Save"
              classList={['smaller']}
              onClick={handleUpdateJobPlacement}
              disabled={disabledButton}
            />
            <Button text="Cancel" classList={['white']} onClick={handleCancelEdit} />
          </>
        )}
      </div>
    </div>
  );
};
export default JPStepOne;
