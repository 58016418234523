import axios from '../utils/axios.config';
import { Pagination, User } from 'interfaces';
import { getUrlWithParams } from 'utils/http.utils';
import { removeEmptyValues } from 'utils/object.utils';
import { TReviewLabel } from 'components/shared/review-label/ReviewLabel';

export interface IBusinessesItem {
  slug: string;
  name: string;
  logo: string | null;
  website: string | null;
  rating: number | null;
  short_description: string | null;
}

export interface IBusinessItemInfo {
  slug: string;
  name: string;
  logo: string;
  website: string;
  rating: number | null;
  short_description: string | null;
  created: string;
  city_or_zip_code: string;
  state: string;
  address: string;
  avg_recommended: string;
  review_count: number;
  sector: string;
  country: string;
  employees_count: number | null;
  rate_sponsorship_mentorship_opportunity: number | null;
  rate_equal_treatment_of_men_and_women: number | null;
  rate_equal_pay_for_equal_performance: number | null;
  rate_equal_opportunities_to_move_up_organization: number | null;
  paid_leave: number | null;
  unpaid_leave: number | null;
  motherhood_supports: string[] | null;
  flexible_hours: number | null;
  job_sharing: number | null;
  working_remotely: number | null;
  part_time_opportunity: number | null;
  most_like_top_three: string[] | null;
  least_like_top_three: string[] | null;
  is_recommended: boolean | null;
  how_likely_to_recommend_company: number | null;
  work_flexibility_options: string[];
}

export interface IMyRatedBusiness {
  business_slug: string;
  employer_status: string;
  employment_status: string;
  gender: string;
  how_likely_to_recommend_company: number;
  job_function: string;
  job_title: string;
  last_year_at_employer: number;
  like_least_about_company_options: string[];
  like_most_about_company_options: string[];
  location: string;
  motherhood_support_options: string[];
  other_notes_about_company: string;
  paid_maternity_weeks: number;
  rate_equal_opportunities_to_move_up_organization: number;
  rate_equal_pay_for_equal_performance: number;
  rate_equal_treatment_of_men_and_women: number;
  rate_sponsorship_mentorship_opportunity: number;
  unpaid_maternity_weeks: number;
  user_age: string;
  user_ethnicity: string;
  user_status: string;
  work_flexibility_options: string[];
}

export interface IBusinessesRes extends Pagination {
  items: IBusinessesItem[];
}
export interface IBusinessReq {
  page: number;
  size: number;
  search_term?: string;
  sort_by?: '' | 'name' | 'rating' | 'ratingDate';
  order?: '' | 'asc' | 'desc';
}

export const businessesApi = (params: IBusinessReq): Promise<IBusinessesRes> => {
  return axios.get(getUrlWithParams(removeEmptyValues(params), '/api/businesses'));
};

export const highlightedBusinesses = (
  p: Pick<Pagination, 'page' | 'size'> = { page: 1, size: 3 },
  limit = 3
): Promise<IBusinessesRes> => {
  return axios.get(`/api/highlightedBusinesses?page=${p.page}&size=${p.size}&limit=${limit}`);
};

export const recentRelatedBusinesses = (
  excluded_business = '',
  p: Pick<Pagination, 'page' | 'size'> = { page: 1, size: 3 },
  limit = 3
): Promise<IBusinessesRes> => {
  return axios.get(
    `/api/recentRatedBusinesses?page=${p.page}&size=${p.size}&limit=${limit}&excluded_business=${excluded_business}`
  );
};

export interface IBusinessDetailParams {
  slug: string;
}
export const businessDetailApi = (params: IBusinessDetailParams): Promise<IBusinessItemInfo> => {
  return axios.get(`/api/business/${params.slug}`);
};

export interface ICompanyReviewsSearchParams {
  term: string;
  page: number;
  size: number;
  sort_by: string;
  order: string;
}

export const companyReviewsSearchApi = (params: ICompanyReviewsSearchParams) => {
  return axios.get('/api/companyReviews', {
    params,
  });
};

export const uploadCompanyLogo = (formData): Promise<{ direct_url: string }> => {
  return axios.post('/api/business_logo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface ICreateCompanyData {
  name: string;
  logo: string;
  website: string;
  short_description: string;
  city_or_zip_code: string;
  user: User[];
  address: string;
  sector: string;
  country: string;
  employees_count: number;
  email: string;
  phone_number: string;
}

export const createCompany = (data: ICreateCompanyData): Promise<any> => {
  return axios.post('/api/business', data);
};

export type TBusinessFilter = TReviewLabel;

export const getMyBusinesses = (filterStatus: TBusinessFilter = ''): Promise<any> => {
  return axios.get(
    getUrlWithParams(
      removeEmptyValues({ filtering_status: filterStatus, page: 1, size: 10 }),
      '/api/my_added_businesses'
    )
  );
};

interface IMyRatingCompany {
  business: IBusinessesItem;
  business_slug: string;
  id: string;
  my_rating: number;
}
interface IMyBusinessRatingRes {
  items: IMyRatingCompany[];
}

export const getMyBusinessRatingApi = (
  p: Pick<Pagination, 'page' | 'size'> = { page: 1, size: 10 },
  limit = 10
): Promise<IMyBusinessRatingRes> => {
  return axios.get(`/api/businessRating/mine?page=${p.page}&size=${p.size}&limit=${limit}`);
};

export interface IBusinessRatingById {
  id: string;
}

export const getMyBusinessRatingBlyIdApi = (
  params: IBusinessRatingById
): Promise<IMyRatedBusiness> => {
  return axios.get(`/api/businessRating?rating_id=${params.id}`);
};

export const deleteBusinessRating = (params: IBusinessRatingById): Promise<unknown> => {
  return axios.delete(`/api/businessRating?rating_id=${params.id}`);
};
