import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardTitle from '../info-card-title/InfoCardTitle';
import InfoCardValue from '../info-card-value/InfoCardValue';

interface IProps {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const MaternityLeave: FC<IProps> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle text={'Maternity Leave'} />
      <InfoCardValue
        label={'Paid leave'}
        value={company.paid_leave ? `${company.paid_leave} weeks` : '—\t'}
        classList={['fullWidth']}
      />
      <InfoCardValue
        label={'Unpaid leave'}
        value={company.unpaid_leave ? `${company.unpaid_leave} weeks` : '—\t'}
        classList={['fullWidth']}
      />
    </PageInfoCard>
  );
};

export default MaternityLeave;
