import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './JobPlacementInfo.module.scss';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import JobPlacementInfoSectionTitle from 'pages/user-added-job-placements/pages/job-placement-info/components/section-title/JobPlacementInfoSectionTitle';
import InfoCardValue from 'components/shared/page-info-card/components/info-card-value/InfoCardValue';
import {
  CULTURAL_PREFERENCES_SECTION,
  JOB_PREFERENCES_SECTION,
  ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_CULTURE,
  ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_GENERAL,
} from 'app-constants';
import InfoCardTitle from 'components/shared/page-info-card/components/info-card-title/InfoCardTitle';
import JobTrend from 'components/shared/job-trend/JobTrend';
import Button from 'components/shared/button/Button';
import deleteIcon from 'assets/images/icons/delete.svg';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  deleteUploadedCV,
  getJobPlacementById,
  IJobPlacementBody,
  patchJobPlacement,
  uploadCVFile,
} from 'api';
import Loader from 'components/common/loader/Loader';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';

export const JobPlacementInfo: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();

  const {
    store: { jobPlacementForm },
    setJobPlacementForm,
    resetStep,
  } = useGlobalJobPlacementContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [isMainPage, setIsMainPage] = useState<boolean>(true);
  const [jobPlacementInfo, setJobPlacementInfo] = useState<IJobPlacementBody>();
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const inputFile = useRef(null);

  useEffect(() => {
    setIsMainPage(
      !location.pathname.includes(ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_GENERAL) &&
        !location.pathname.includes(ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_CULTURE)
    );

    getJobPlacementById(params?.id || '')
      .then((res) => {
        setJobPlacementInfo(res);
        setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, `Request №${res.id}`);
      })
      .finally(() => setLoading(false));
  }, [location]);

  useEffect(
    () => () => {
      resetStep();
    },
    []
  );

  const handleGeneralInfoEditClick = () => {
    setJobPlacementForm({
      ...jobPlacementForm,
      ...jobPlacementInfo,
    });
    navigate(`${ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_GENERAL}`);
  };

  const handleCulturalPrefsEditClick = () => {
    setJobPlacementForm({
      ...jobPlacementForm,
      ...jobPlacementInfo,
    });
    navigate(`${ROUTE_USER_ADDED_JOB_PLACEMENT_EDIT_CULTURE}`);
  };

  const handleFileInputChange = (event) => {
    const formData = new FormData();
    formData.append('cv_file', event.target.files[0]);
    setFileUploading(true);
    uploadCVFile(formData)
      .then((res) => {
        patchJobPlacement(jobPlacementInfo!.id!, { cv_link: res.direct_url }).then(() => {
          setJobPlacementInfo({ ...jobPlacementInfo, cv_link: res.direct_url });
          setFileUploading(false);
        });
      })
      .catch((e) => {
        console.error(e);
        setFileUploading(false);
      });
  };

  const onUploadClick = () => {
    /* eslint-disable */
    const target = inputFile?.current! as HTMLElement;
    target && target.click();
  };

  const onDeleteCVClick = () => {
    setFileUploading(true);
    deleteUploadedCV(jobPlacementInfo!.id!, jobPlacementInfo!.cv_link!).then(() => {
      patchJobPlacement(jobPlacementInfo!.id!, { cv_link: null }).then(
        () => {
          setJobPlacementInfo({ ...jobPlacementInfo, cv_link: null });
          setFileUploading(false);
        }
      );
    });
  }

  const handleDownloadCV = () => {
    window.open(jobPlacementInfo!.cv_link!, '_blank', 'noopener,noreferrer');
  }

  return (
    <div className={styles.jobPlacementInfoWrapper}>
      {loading || !jobPlacementInfo ? (
        <div className="flexWrapper flexWrapper--center">
          <Loader />
        </div>
      ) : (
        <>
          {isMainPage ? (
            <>
              <PageInfoCard classList={['no-margin-top']}>
                <JobPlacementInfoSectionTitle
                  title={'General Information'}
                  editable={true}
                  buttonText={'Edit'}
                  onClickEdit={handleGeneralInfoEditClick}
                />
                <div className={styles.sections}>
                  <div className={styles.section}>
                    <InfoCardValue
                      label={'Current employment status'}
                      value={jobPlacementInfo!.current_employment_status!}
                      classList={['fullWidth', 'longLabel']}
                    />
                  </div>
                  <div className={styles.section}>
                    <InfoCardValue
                      label={'Job Title'}
                      value={jobPlacementInfo!.current_job_category!}
                      classList={['fullWidth']}
                    />
                    <InfoCardValue
                      label={'Job Function'}
                      value={jobPlacementInfo!.current_job_title!}
                      classList={['fullWidth']}
                    />
                  </div>
                  <div className={styles.section}>
                    <InfoCardValue
                      label={'Country'}
                      value={jobPlacementInfo!.current_country!}
                      classList={['fullWidth']}
                    />
                    <InfoCardValue
                      label={`${
                        jobPlacementInfo.current_country === 'United States' ? 'Zip Code' : 'City'
                      }`}
                      value={jobPlacementInfo!.current_zip_code_or_city!}
                      classList={['fullWidth']}
                    />
                  </div>
                </div>
                <JobPlacementInfoSectionTitle title={'Job Preferences'} />
                <div className={styles.sections}>
                  {JOB_PREFERENCES_SECTION.map((section, i) => {
                    return (
                      <div key={`section-${i}`} className={styles.section}>
                        <InfoCardTitle text={section.label} />
                        {typeof jobPlacementInfo[section.key] === 'object' ? (
                          jobPlacementInfo[section.key].map((item, j) => (
                            <span key={`option-${j}`} className={`${styles.sectionValue}`}>
                              {`${
                                j !== jobPlacementInfo[section.key].length - 1 ? item + ',' : item
                              }`}
                            </span>
                          ))
                        ) : (
                          <span key={`option-${i}`} className={`${styles.sectionValue}`}>
                            {jobPlacementInfo[section.key]}
                          </span>
                        )}
                      </div>
                    );
                  })}
                </div>
              </PageInfoCard>
              <PageInfoCard classList={['no-margin-top']}>
                <JobPlacementInfoSectionTitle
                  title={'Cultural Preferences'}
                  editable={true}
                  buttonText={'Edit'}
                  onClickEdit={handleCulturalPrefsEditClick}
                />
                <div className={styles.sections}>
                  {CULTURAL_PREFERENCES_SECTION.map((section, i) => {
                    return (
                      <div key={`section-${i}`} className={`${styles.section} ${styles.withTrend}`}>
                        <InfoCardTitle text={section.label} />
                        <div className={'flexWrapper'}>
                          {jobPlacementInfo[section.key].map((item, j) => {
                            return (
                              <JobTrend
                                key={`trend-${j}`}
                                text={item}
                                clickable={false}
                                selected={true}
                              />
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </PageInfoCard>
              <PageInfoCard classList={['no-margin-top']}>
                <JobPlacementInfoSectionTitle
                  title={`${jobPlacementInfo.cv_link ? 'Uploaded CV' : 'Upload CV'}`}
                />
                {fileUploading ? (
                  <div style={{ margin: '15px 0' }}>
                    <Loader classList={['autoHeight']} />
                  </div>
                ) : (
                  <>
                    {jobPlacementInfo.cv_link && (
                      <div className={styles.cvWrapper}>
                        <Button text={'Download CV'} classList={['hardGray']} onClick={handleDownloadCV} />
                        <Button
                          text={''}
                          type={'icon'}
                          iconLink={deleteIcon}
                          onClick={onDeleteCVClick}
                        />
                      </div>
                    )}
                    <div className={styles.uploadAnother}>
                      <Button
                        text={`${jobPlacementInfo.cv_link ? 'Upload another CV' : 'Upload CV'}`}
                        classList={['borderedYellow']}
                        onClick={onUploadClick}
                      />
                      <input type="file" id="file" ref={inputFile} hidden onChange={handleFileInputChange} />
                    </div>
                  </>
                )}
              </PageInfoCard>
            </>
          ) : (
            <div className="job-placement-container no-margin">
              <Outlet context={{jobPlacementInfo}} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default JobPlacementInfo;
