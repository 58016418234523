import { IJobPlacementBody } from 'api/job-placement';
import { ROUTE_JOB_PLACEMENT, SOCIAL_AUTH_SAVE_DATA } from 'app-constants';
import React, { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import AuthHelper, { getCookie, removeCookie } from '../utils/AuthHelpers';
import { JOB_EMPLOYMENT_STATUS } from 'pages/job-placement/sub-pages/data/Step.data';
import { useNavigate } from 'react-router-dom';

interface IStepModel {
  id: number;
  name: string;
  route: string;
  isActivated: boolean;
}

type GlobalJobPlacementContext = {
  setJobPlacementForm: (body: IJobPlacementBody, resolve?: () => void) => void;
  setSteps: (body: IStepModel[], resolve?: () => void) => void;
  handleClickStep: (step: IStepModel, resolve?: () => void) => void;
  resetStep: (resolve?: () => void) => void;
  setIsLogin: (body: boolean, resolve?: () => void) => void;
  store: any;
};

/* eslint-disable */
const initialState: GlobalJobPlacementContext = {
  setJobPlacementForm: () => {},
  setSteps: () => {},
  handleClickStep: () => {},
  resetStep: () => {},
  setIsLogin: () => {},
  store: {
    isLogin: !!AuthHelper.getAccessToken(),
    jobPlacementForm: {
      current_employment_status: JOB_EMPLOYMENT_STATUS[0].value,
      current_job_title: '',
      current_job_category: '',
      current_zip_code_or_city: '',
      current_country: '',
      selected_job_title: '',
      selected_job_category: '',
      selected_work_preference: [],
      cv_link: '',
      selected_job_opportunity_type: [],
      selected_work_flexibility: [],
      selected_commute_options: [],
      selected_travel_options: [],
      selected_choosing_employer_importance_options: [],
      selected_what_motivates_best_job_options: [],
      selected_deal_breaker_options: [],
      notification_new_companies_are_rated: false,
      notification_latest_women_news: false,
      notification_receive_job_alerts: false,
    },
    steps: [
      { id: 0, name: 'Step 1', route: `/${ROUTE_JOB_PLACEMENT}`, isActivated: false },
      { id: 1, name: 'Step 2', route: `/${ROUTE_JOB_PLACEMENT}/step2`, isActivated: false },
      { id: 2, name: 'Step 3', route: `/${ROUTE_JOB_PLACEMENT}/step3`, isActivated: false },
      { id: 3, name: 'Step 4', route: `/${ROUTE_JOB_PLACEMENT}/step4`, isActivated: false },
    ],
  },
};

export const GlobalJobPlacementContext = createContext(initialState);
export const useGlobalJobPlacementContext = () => useContext(GlobalJobPlacementContext);

export const GlobalJobPlacement: FC<{ children: ReactNode }> = ({ children }) => {
  const cookieData = getCookie(SOCIAL_AUTH_SAVE_DATA);
  const [store, setStore] = useState(
    cookieData
      ? {
          ...initialState.store,
          jobPlacementForm: {
            ...initialState.store.jobPlacementForm,
            ...cookieData.data,
          },
        }
      : initialState.store
  );

  const accessToken = AuthHelper.getAccessToken();
  const navigate = useNavigate();

  const setJobPlacementForm = (body: IJobPlacementBody, resolve?: () => void) => {
    setStore({
      ...store,
      jobPlacementForm: {
        ...store.jobPlacementForm,
        ...body,
      },
    });
    resolve?.();
  };

  const setSteps = (steps: IStepModel[], resolve?: () => void) => {
    setStore({
      ...store,
      steps,
    });
    resolve?.();
  };

  const resetStep = (resolve?: () => void) => {
    const newStore = {
      isLogin: accessToken || store.isLogin,
      jobPlacementForm: {
        current_employment_status: JOB_EMPLOYMENT_STATUS[0].value,
        current_job_title: '',
        current_job_category: '',
        current_zip_code_or_city: '',
        current_country: '',
        selected_job_title: '',
        selected_job_category: '',
        selected_work_preference: [],
        cv_link: '',
        selected_job_opportunity_type: [],
        selected_work_flexibility: [],
        selected_commute_options: [],
        selected_travel_options: [],
        selected_choosing_employer_importance_options: [],
        selected_what_motivates_best_job_options: [],
        selected_deal_breaker_options: [],
        notification_new_companies_are_rated: false,
        notification_latest_women_news: false,
        notification_receive_job_alerts: false,
      },
      steps: !store.isLogin
        ? [
            { id: 0, name: 'Step 1', route: `/${ROUTE_JOB_PLACEMENT}`, isActivated: false },
            { id: 1, name: 'Step 2', route: `/${ROUTE_JOB_PLACEMENT}/step2`, isActivated: false },
            { id: 2, name: 'Step 3', route: `/${ROUTE_JOB_PLACEMENT}/step3`, isActivated: false },
            { id: 3, name: 'Step 4', route: `/${ROUTE_JOB_PLACEMENT}/step4`, isActivated: false },
            { id: 4, name: 'Step 5', route: `/${ROUTE_JOB_PLACEMENT}/step5`, isActivated: false },
          ]
        : [
            { id: 0, name: 'Step 1', route: `/${ROUTE_JOB_PLACEMENT}`, isActivated: false },
            { id: 1, name: 'Step 2', route: `/${ROUTE_JOB_PLACEMENT}/step2`, isActivated: false },
            { id: 2, name: 'Step 3', route: `/${ROUTE_JOB_PLACEMENT}/step3`, isActivated: false },
            { id: 3, name: 'Step 4', route: `/${ROUTE_JOB_PLACEMENT}/step5`, isActivated: false },
          ],
    };
    setStore(newStore);

    resolve?.();
  };

  const handleClickStep = (step: IStepModel, resolve?: () => void) => {
    const newStep = store.steps.map((item: IStepModel) => {
      item.isActivated = step.id > item.id;

      return item;
    });
    setSteps(newStep);
    resolve?.();
  };

  const setIsLogin = (body: boolean, resolve?: () => void) => {
    setStore({
      ...store,
      isLogin: body,
    });
    resolve?.();
  };

  useEffect(() => {
    if (accessToken || store.isLogin) {
      if (store.steps.length === 5) {
        const newSteps = store.steps
          .filter((item) => item.id !== 3)
          .map((item) => {
            if (item.id === 4) {
              return { ...item, id: 3, name: 'Step 4' };
            }

            return item;
          });

        setSteps(newSteps);
      }
    } else {
      const newSteps = [
        { id: 0, name: 'Step 1', route: `/${ROUTE_JOB_PLACEMENT}`, isActivated: false },
        { id: 1, name: 'Step 2', route: `/${ROUTE_JOB_PLACEMENT}/step2`, isActivated: false },
        { id: 2, name: 'Step 3', route: `/${ROUTE_JOB_PLACEMENT}/step3`, isActivated: false },
        { id: 3, name: 'Step 4', route: `/${ROUTE_JOB_PLACEMENT}/step4`, isActivated: false },
        { id: 4, name: 'Step 5', route: `/${ROUTE_JOB_PLACEMENT}/step5`, isActivated: false },
      ].map((step) => {
        const prevStateOfStep = store.steps.find((i) => i.id === step.id);
        return { ...step, isActivated: prevStateOfStep?.isActivated || false };
      });

      const completedSteps = store.steps.map((i) => i.isActivated);
      const lastCompletedStep = completedSteps.lastIndexOf(true);

      if (lastCompletedStep > 0) {
        navigate(store.steps[lastCompletedStep].route);
      }

      setSteps(newSteps);
    }
  }, [accessToken, store.isLogin]);

  return (
    <GlobalJobPlacementContext.Provider
      value={{
        store,
        setJobPlacementForm,
        setSteps,
        handleClickStep,
        resetStep,
        setIsLogin,
      }}
    >
      {children}
    </GlobalJobPlacementContext.Provider>
  );
};
/* eslint-enable */
