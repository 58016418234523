import React, { FC, useEffect, useRef, useState } from 'react';
import { useGlobalJobPlacementContext } from 'contexts/GlobalJobPlacementContext';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import PageTitle from 'components/common/page-title/PageTitle';
import { ROUTE_JOB_PLACEMENT } from 'app-constants';
import Button from 'components/shared/button/Button';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { uploadCVFile } from 'api';
import Loader from 'components/common/loader/Loader';

export const JPStepFour: FC = () => {
  const {
    store: { jobPlacementForm, steps },
    setJobPlacementForm,
    handleClickStep,
  } = useGlobalJobPlacementContext();

  const inputFile = useRef(null);
  const [fileUploading, setFileUploading] = useState(false);
  const [fileUploadingError, setFileUploadingError] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<any>();

  const navigate = useNavigate();

  const handleClickNext = () => {
    if (uploadedFile) {
      setJobPlacementForm({
        ...jobPlacementForm,
        cv_link: uploadedFile.cv_link,
      });
    }

    navigate(`/${ROUTE_JOB_PLACEMENT}/step5`);
  };

  const handleBackClick = () => {
    navigate(`/${ROUTE_JOB_PLACEMENT}/step2`);
  };

  const onUploadClick = () => {
    /* eslint-disable */
    const target = inputFile?.current! as HTMLElement;
    target && target.click();
  };

  const handleFileInputChange = (event) => {
    let formData = new FormData();
    formData.append('cv_file', event.target.files[0]);
    setFileUploading(true);
    setFileUploadingError('');
    uploadCVFile(formData)
      .then((res) => {
        setUploadedFile({ file: event.target.files[0], cv_link: res.direct_url });
        setFileUploading(false);
      })
      .catch((e) => {
        console.error(e);
        setFileUploading(false);
        setUploadedFile(null);

        if (e.status === 403) {
          setFileUploadingError(e.data.detail);
        }
      });
  };

  useEffect(() => {
    const filterStep = steps.find((item) => item.id === 2);
    handleClickStep(filterStep);
  }, []);

  return (
    <PageInfoCard classList={['center']}>
      <PageTitle
        type={'card'}
        title={'Upload your CV'}
        subTitle={
          'You can upload only 1 CV into your Job Placement Request. If you want to change it, you can do it in your personal account page inside every Job Placement request you’ve sent.'
        }
      />

      <div className="info-card-content center">
        {!fileUploading ? (
          <Button
            text={uploadedFile ? 'Upload another CV' : 'Upload my CV'}
            classList={['red', 'withPlus']}
            onClick={onUploadClick}
          />
        ) : (
          <Loader classList={['autoHeight']} />
        )}
        {uploadedFile && !fileUploadingError && (
          <span style={{ padding: '20px 0 0', fontWeight: 'bold' }}>
            Uploaded file: {uploadedFile.file['name']}
          </span>
        )}
        {fileUploadingError && (
          <span style={{ padding: '20px 0 0', fontWeight: 'bold', color: '#da0000' }}>
            {fileUploadingError}
          </span>
        )}
        <input type="file" id="file" ref={inputFile} hidden onChange={handleFileInputChange} />
        <div className="buttons-group">
          <Button
            text={'Go Back'}
            disabled={fileUploading}
            classList={['white']}
            onClick={handleBackClick}
          />
          <Button
            text={'Skip'}
            disabled={fileUploading}
            classList={['white']}
            onClick={handleClickNext}
          />
          <Button text={'Next Step'} disabled={fileUploading} onClick={handleClickNext} />
        </div>
      </div>
    </PageInfoCard>
  );
};

export default JPStepFour;
