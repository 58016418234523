import React, { FC, useEffect, useState } from 'react';
import Cards from 'components/shared/cards/Cards';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICard } from 'interfaces';
import { ROUTE_ADD_COMPANY, ROUTE_ADD_RATING, ROUTE_COMPANY_RATING } from 'app-constants';
import Select, { ISelectOption } from 'components/shared/form-controls/select/Select';
import { useForm } from 'react-hook-form';
import styles from './UserAddedCompanies.module.scss';
import InfoBanner from 'components/shared/info-banner/InfoBanner';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import { getMyBusinesses } from 'api';
import Loader from 'components/common/loader/Loader';
import { TReviewLabel } from 'components/shared/review-label/ReviewLabel';

interface IForm {
  sort: any;
}

export const UserAddedCompanies: FC = () => {
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(true);
  const [businesses, setBusinesses] = useState<ICard[] | []>([]);
  const [noDataText, setNoDataText] = useState<{ title: string; text: string }>({
    title: 'You didn’t add any Companies yet',
    text: 'Add your first company, so it will be visible on our website and other people can rate your company',
  });
  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();

  const navigate = useNavigate();

  const location = useLocation();

  const filterOptions: ISelectOption[] = [
    { label: 'All', value: '' },
    { label: 'Approved', value: 'Approved' },
    { label: 'Not Reviewed', value: 'Not Reviewed' },
    { label: 'Rejected', value: 'Rejected' },
  ];

  const { control, watch, setValue } = useForm<IForm>({
    defaultValues: {
      sort: filterOptions[0].value,
    },
  });
  const watchSort = watch('sort', false);

  useEffect(() => {
    setValue('sort', filterOptions[0].value);
  }, [location]);

  useEffect(() => {
    if (typeof watchSort !== 'boolean') {
      setBusinesses([]);
      getMyBusinessesApi(watchSort || '');
    }

    switch (watchSort) {
      case 'Approved':
        setNoDataText({
          title: 'You don’t have any approved Companies',
          text: 'Please wait until your company is approved or add a new one.',
        });
        return;
      case 'Not Reviewed':
        setNoDataText({
          title: 'You don’t have any Companies in review',
          text: '',
        });
        return;
      case 'Rejected':
        setNoDataText({
          title: 'You don’t have any rejected Companies',
          text: '',
        });
        return;
      default:
        setNoDataText({
          title: 'You didn’t add any Companies yet',
          text: 'Add your first company, so it will be visible on our website and other people can rate your company',
        });
        return;
    }
  }, [watchSort]);

  const handleAddRatingButtonClick = () => {
    navigate(`${businesses[0].link}/${ROUTE_ADD_RATING}`);
    setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, businesses[0]?.title);
  };

  const handleAddCompanyButtonClick = () => {
    navigate(`/${ROUTE_COMPANY_RATING}/${ROUTE_ADD_COMPANY}`);
  };

  const getMyBusinessesApi = (filterStatus: TReviewLabel = '') => {
    setIsBusinessLoading(true);

    getMyBusinesses(filterStatus).then((res) => {
      const newData: ICard[] | [] = res.items?.map((el) => ({
        type: 'company',
        description: el.short_description || '',
        imageUrl: el.logo || '',
        link: `/${ROUTE_COMPANY_RATING}/${el.slug}`,
        rating: null,
        title: el.name,
        showLearnMoreBtn: false,
        reviewLabel: el.status,
      }));

      setBusinesses(newData || []);
      setIsBusinessLoading(false);
    });
  };

  return (
    <section className="section">
      <div className="section__block no-top-padding">
        <div className={styles.content}>
          <div className={`filters ${styles.filters}`}>
            <form className="form">
              <Select
                options={filterOptions}
                control={control}
                name={'sort'}
                selectLabel={'Filter by:'}
                selectPlaceholder={'Select the option'}
              />
            </form>
          </div>
          {isBusinessLoading ? (
            <div className="flexWrapper flexWrapper--center">
              <Loader />
            </div>
          ) : (
            <>
              {businesses.length > 0 && !isBusinessLoading ? (
                <>
                  <Cards cards={businesses} isLoading={isBusinessLoading} />
                  {businesses.length > 0 && (
                    <InfoBanner
                      title={`Be the First one to Rate ${businesses[0]?.title}`}
                      text={
                        'All ratings will remain 100% anonymous. Your privacy is important to us.'
                      }
                      closable={true}
                      buttonText={'Add Rating'}
                      buttonClassList={['red', 'withPlus']}
                      onButtonClick={handleAddRatingButtonClick}
                    />
                  )}
                </>
              ) : (
                <InfoBanner
                  title={noDataText?.title || ''}
                  text={noDataText?.text || ''}
                  classList={['yellow']}
                  buttonText={'Add a new Company'}
                  buttonClassList={['withPlus']}
                  onButtonClick={handleAddCompanyButtonClick}
                />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default UserAddedCompanies;
