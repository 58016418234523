import { IBusinessItemInfo } from 'api';
import React, { FC } from 'react';
import PageInfoCard from '../../PageInfoCard';
import InfoCardScale from '../info-card-scale/InfoCardScale';
import InfoCardTitle from '../info-card-title/InfoCardTitle';

interface IProps {
  isMyRated?: boolean;
  company: IBusinessItemInfo;
}

const OnScale: FC<IProps> = ({ company, isMyRated }) => {
  return (
    <PageInfoCard classList={company.review_count > 0 || isMyRated ? [] : ['blured']}>
      <InfoCardTitle
        text={`On a scale of 0-5, how likely is ${company.name} recommendable to another woman`}
      />
      {company.how_likely_to_recommend_company ? (
        <InfoCardScale selected={company.how_likely_to_recommend_company} />
      ) : (
        '—\t'
      )}
    </PageInfoCard>
  );
};

export default OnScale;
