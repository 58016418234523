import React, { FC, useEffect } from 'react';
import ModalWindow from 'components/shared/modal-window/ModalWindow';
import { useGlobalModalContext } from 'contexts/GlobalModalContext';
import successImage from 'assets/images/icons/checked.svg';
import Button from 'components/shared/button/Button';
import { useNavigate } from 'react-router-dom';
import { ROUTE_COMPANY_RATING, ROUTE_USER_ADDED_JOB_PLACEMENTS } from 'app-constants';
import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import InfoCardTitle from 'components/shared/page-info-card/components/info-card-title/InfoCardTitle';
import InfoCardValue from 'components/shared/page-info-card/components/info-card-value/InfoCardValue';
import styles from 'pages/user-added-job-placements/components/job-placement-card/JobPlacementCard.module.scss';
import deleteIcon from 'assets/images/icons/delete.svg';
import { deleteJobPlacement } from 'api';

export const DeleteAddedJobPlacementModal: FC = () => {
  const {
    hideModal,
    store: { modalProps },
  } = useGlobalModalContext();

  const navigate = useNavigate();

  const handleCloseModal = () => {
    hideModal();
  };

  const handleDeleteClick = () => {
    deleteJobPlacement(modalProps.id).then(() => {
      handleCloseModal();
      navigate(`/${ROUTE_USER_ADDED_JOB_PLACEMENTS}`);
    });
  };

  return (
    <ModalWindow visible={true} setVisible={handleCloseModal}>
      <div style={{ width: '100%' }}>
        <InfoCardTitle text={'Are you sure you want to delete this request?'} />
        <InfoCardValue
          label={'Job Title'}
          value={modalProps.current_job_title}
          classList={['fullWidth', 'label35']}
        />
        <InfoCardValue
          label={'Job Function'}
          value={modalProps.current_job_category}
          classList={['fullWidth', 'label35']}
        />
        <InfoCardValue
          label={'Request Date'}
          value={modalProps.created}
          classList={['fullWidth', 'label35']}
        />
        <div className={`flexWrapper ${styles.buttons}`}>
          <Button text={'Cancel'} classList={['white']} onClick={handleCloseModal} />
          <Button text={'Yes, Delete'} classList={['red', 'smaller']} onClick={handleDeleteClick} />
        </div>
      </div>
    </ModalWindow>
  );
};

export default DeleteAddedJobPlacementModal;
