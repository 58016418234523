import React, { FC, useEffect, useRef, useState } from 'react';
import { Path, useForm, UseFormRegister, ValidationRule } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { uploadCVFile } from 'api';
import Button from 'components/shared/button/Button';
import Loader from 'components/common/loader/Loader';

type InputType = 'text' | 'password' | 'number' | 'date' | 'file';

type InputProps = {
  label: Path<any>;
  register: UseFormRegister<any>;
  type: InputType;
  placeholder?: string;
  errors?: FieldErrors<any>;
  required?: boolean;
  inputLabel?: string;
  value?: any;
  classList?: string[];
  labelClassList?: string[];
  pattern?: ValidationRule<RegExp> | undefined;
  onFileAdded?: (file: File) => void;
  onFileUploading?: boolean;
};

export const Input: FC<InputProps> = ({
  label,
  inputLabel,
  register,
  required = false,
  type,
  placeholder = '',
  errors = {},
  pattern = undefined,
  classList = [],
  labelClassList = [],
  onFileAdded,
  onFileUploading,
}) => {
  const [showPass, setShowPass] = useState(false);
  const [inputType, setInputType] = useState(type);
  const inputFile = useRef(null);

  useEffect(() => {
    if (type === 'password') {
      setInputType(showPass ? 'text' : 'password');
    }
  }, [showPass]);

  const handleFileInputChange = (event) => {
    onFileAdded?.(event.target.files[0]);
  };

  const onUploadClick = (event) => {
    /* eslint-disable */
    event.preventDefault();
    event.stopPropagation();
    const target = inputFile?.current! as HTMLElement;
    target && target.click();
  };

  return (
    <div className={`form-group ${type === 'number' ? 'number' : ''}`}>
      {inputLabel && <label className={`label ${labelClassList?.join(' ')}`}>{inputLabel}</label>}
      <div className={`input-wrapper ${classList.join(' ')}`}>
        {type !== 'file' && (
          <>
            <input
              className={`input ${errors && errors[label] && 'field-invalid'}`}
              type={inputType}
              placeholder={placeholder}
              {...register(label, { required, pattern })}
            />
            {type === 'password' && (
              <span
                className={`eye-icon ${showPass && 'show'}`}
                onClick={() => setShowPass(!showPass)}
              />
            )}
          </>
        )}
        {type === 'file' && (
          <>
            {onFileUploading ? (
              <div className="flexWrapper flexWrapper--center">
                <Loader classList={['autoHeight']} />
              </div>
            ) : (
              <>
                <Button
                  text={placeholder}
                  classList={['red', 'withPlus']}
                  onClick={(event) => onUploadClick(event)}
                />
                <input type="file" id="file" ref={inputFile} hidden onChange={handleFileInputChange} />
              </>
            )}
          </>
        )}
      </div>

      {errors &&
        errors[label]?.type &&
        (errors[label]?.type === 'required' ? (
          <span className={'validation-error'}>This field must be filled</span>
        ) : (
          <span className={'validation-error'}>{errors[label]?.message}</span>
        ))}
    </div>
  );
};

export default Input;
