import axios from '../utils/axios.config';
import { getUrlWithParams } from 'utils/http.utils';
import { TReviewLabel } from 'components/shared/review-label/ReviewLabel';

export interface IJobPlacementBody {
  id?: string;
  status?: TReviewLabel;
  current_employment_status?: string;
  current_job_title?: string;
  current_job_category?: string;
  current_zip_code_or_city?: string;
  current_country?: string;
  selected_work_preference?: string[];
  selected_job_title?: string;
  selected_job_category?: string;
  cv_link?: string | null;
  selected_job_opportunity_type?: string[];
  selected_work_flexibility?: string[];
  selected_commute_options?: string[];
  selected_travel_options?: string[];
  selected_choosing_employer_importance_options?: string[];
  selected_what_motivates_best_job_options?: string[];
  selected_deal_breaker_options?: string[];
  notification_new_companies_are_rated?: boolean;
  notification_latest_women_news?: boolean;
  notification_receive_job_alerts?: boolean;
  created?: string;
}

export interface IRecommendToFriendsBody {
  selected_prize_option?: any;
  user_fullname?: string;
  friend_emails?: string[];
  message?: string;
}

export const submitJobPlacement = (body: IJobPlacementBody): Promise<unknown> => {
  return axios.post('/api/jobPlacement', body);
};

export const recommendToFriends = (body: IRecommendToFriendsBody): Promise<unknown> => {
  return axios.post('/api/recommendToFriends', body);
};

export const uploadCVFile = (formData): Promise<{ direct_url: string }> => {
  return axios.post('/api/jobPlacement/upload_cv', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export interface IMyJobPlacementsResp {
  items: IJobPlacementBody[];
}

export const getMyJobPlacements = (): Promise<IMyJobPlacementsResp> => {
  return axios.get('/api/jobPlacement/mine');
};

export const getJobPlacementById = (id: string): Promise<IJobPlacementBody> => {
  return axios.get(`/api/jobPlacement/by_id/${id}`);
};

export const patchJobPlacement = (id: string, data: any): Promise<any> => {
  return axios.patch('/api/jobPlacement', { id, ...data });
};

export const deleteUploadedCV = (id: string, cv_link: string): Promise<unknown> => {
  return axios.delete(getUrlWithParams({ jobPlacement_id: id, cv_link }, '/api/jobPlacement/cv'));
};

export const deleteJobPlacement = (placement_id: string): Promise<unknown> => {
  return axios.delete(getUrlWithParams({ placement_id }, `/api/jobPlacement/${placement_id}`));
};
