import React, { FC, useEffect, useState } from 'react';
import styles from './CompanyInfo.module.scss';

import PageInfoCard from 'components/shared/page-info-card/PageInfoCard';
import InfoCardValue from 'components/shared/page-info-card/components/info-card-value/InfoCardValue';
import PageTitle from 'components/common/page-title/PageTitle';
import Cards from 'components/shared/cards/Cards';
import { ICard } from 'interfaces/card';
import Button from 'components/shared/button/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE_ADD_RATING, ROUTE_COMPANY_RATING } from 'app-constants';
import { businessDetailApi, IBusinessItemInfo, recentRelatedBusinesses } from 'api';
import Loader from 'components/common/loader/Loader';
import { BREADCRUMBS_TYPES, useDynamicBreadcrumbContext } from 'contexts/DynamicBreadcrumbContext';
import EqualOpportunity from 'components/shared/page-info-card/components/equal-opportunity/EqualOpportunity';
import MaternityLeave from 'components/shared/page-info-card/components/maternity-leave/MaternityLeave';
import MotherhoodSupport from 'components/shared/page-info-card/components/motherhood-support/MotherhoodSupport';
import WorkFlexibility from 'components/shared/page-info-card/components/work-flexibility/WorkFlexibility';
import MostLike from 'components/shared/page-info-card/components/most-like/MostLike';
import LeastLike from 'components/shared/page-info-card/components/least-like/LeastLike';
import OnScale from 'components/shared/page-info-card/components/on-scale/OnScale';

export const CompanyInfo: FC = () => {
  const [businesses, setBusinesses] = useState<ICard[] | []>([]);
  const [isBusinessLoading, setIsBusinessLoading] = useState<boolean>(true);
  const [company, setCompany] = useState<IBusinessItemInfo>();
  const [isCompanyLoading, setIsCompanyLoading] = useState<boolean>(true);
  const { setDynamicBreadcrumb } = useDynamicBreadcrumbContext();

  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();

  useEffect(() => {
    setIsCompanyLoading(true);
    setIsBusinessLoading(true);

    recentRelatedBusinesses(slug || '').then((res) => {
      const newData: ICard[] | [] = res.items?.map((el) => ({
        type: 'company',
        description: el.short_description || '',
        imageUrl: el.logo || '',
        link: `/${ROUTE_COMPANY_RATING}/${el.slug}`,
        rating: el.rating || 0,
        title: el.name,
      }));

      setBusinesses(newData || []);
      setIsBusinessLoading(false);
    });

    loadCompanyData();
  }, [location]);

  const loadCompanyData = () => {
    setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, '');
    businessDetailApi({ slug: slug || '' }).then((res) => {
      setCompany(res);
      setDynamicBreadcrumb(BREADCRUMBS_TYPES.COMPANY, res.name);
      setIsCompanyLoading(false);
    });
  };

  return (
    <>
      {!company || isCompanyLoading ? (
        <div className="flexWrapper flexWrapper--center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="section__block no-top-padding no-bottom-padding">
            <PageInfoCard>
              <div className={styles.mainInfoWrapper}>
                <div className={styles.infoContent}>
                  <img className={styles.companyImage} src={company.logo} alt={'company logo'} />
                  <div className={styles.mainInfo}>
                    <div className={styles.mainInfoData}>
                      <h1 className={styles.companyName}>{company.name}</h1>
                      <span className={styles.companyLocation}>
                        {company.city_or_zip_code
                          ? `${
                              company.city_or_zip_code === 'United States' ? 'Zip Code:' : 'City:'
                            } ${company.city_or_zip_code}`
                          : ''}
                      </span>
                    </div>
                    {company.review_count > 0 ? (
                      <>
                        <div className={styles.recommendation}>
                          <div
                            className={[
                              styles.recommendationBlock,
                              company.is_recommended ? styles.good : styles.failed,
                            ].join(' ')}
                          >
                            {company.is_recommended ? 'Recommended' : 'Not Recommended'}
                          </div>
                          <span className={styles.recommendationCount}>
                            {company.review_count} Reviews
                          </span>
                        </div>
                        <div className={styles.recommenderToFriends}>
                          <span className={`${styles.recommenderToFriendsStatus} ${styles.like}`} />
                          <span>{company.avg_recommended || 0} % Recommend to Friend</span>
                        </div>
                      </>
                    ) : (
                      <div className={styles.noRating}>
                        <span>We couldn’t find any ratings of this company in our database. </span>
                        <span className={styles.beFirst}>Be the first to rate this company.</span>
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  text={'Add Rating'}
                  classList={['red', 'withPlus']}
                  onClick={() => navigate(`${location.pathname}/${ROUTE_ADD_RATING}`)}
                />
              </div>
            </PageInfoCard>
            <PageInfoCard>
              <InfoCardValue label={'Website'} value={company.website || '—\t'} />
              <InfoCardValue label={'Country'} value={company.country || '—\t'} />
              <InfoCardValue label={'Sector'} value={company.sector || '—\t'} />
              <InfoCardValue
                label={'Employees'}
                value={company.employees_count?.toString() || '—\t'}
              />
            </PageInfoCard>
            <EqualOpportunity company={company} />
            <MaternityLeave company={company} />
            <MotherhoodSupport company={company} />
            <WorkFlexibility company={company} />
            <MostLike company={company} />
            <LeastLike company={company} />
            <OnScale company={company} />
          </div>
          <div className="section__block">
            <PageTitle title={'Recent Ratings'} classList={['sectionTitle']} />
            <Cards cards={businesses} button={null} isLoading={isBusinessLoading} />
          </div>
        </>
      )}
    </>
  );
};

export default CompanyInfo;
