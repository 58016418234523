export const JOB_PREFERENCES_SECTION = [
  {
    key: 'selected_job_opportunity_type',
    label: 'What kind of job opportunity are you looking for?',
  },
  { key: 'selected_work_preference', label: 'What is your work preference?' },
  { key: 'selected_job_title', label: 'What is a job title you are looking for?' },
  { key: 'selected_job_category', label: 'What is a job function you are interested in?' },
  {
    key: 'selected_work_flexibility',
    label: 'Which of the following work flexibility options are you interested in?',
  },
  {
    key: 'selected_commute_options',
    label: 'How much commute are you willing to do for your job?',
  },
  { key: 'selected_travel_options', label: 'How much travel are you willing to do for your job?' },
];

export const CULTURAL_PREFERENCES_SECTION = [
  {
    key: 'selected_choosing_employer_importance_options',
    label: 'As a woman when choosing an employer, what is important to you?',
  },
  {
    key: 'selected_what_motivates_best_job_options',
    label: 'What motivates you to do the best job you possibly can?',
  },
  { key: 'selected_deal_breaker_options', label: 'What is a deal breaker for you?' },
];
