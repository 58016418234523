import React, { FC } from 'react';
import styles from './UserAddedJobPlacement.module.scss';
import { Outlet, useNavigate } from 'react-router-dom';

export const UserAddedJobPlacement: FC = () => {
  return (
    <section className="section">
      <div className="section__block no-top-padding">
        <div className={styles.jobPlacementsWrapper}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};
